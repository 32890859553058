/*global google*/
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Wrapper, Status } from "@googlemaps/react-wrapper"
import LinearProgress from "@mui/material/LinearProgress"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Switch from "@mui/material/Switch"
import "../styles/Map.css"
import LayersIcon from "@mui/icons-material/Layers"
import { IconButton } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"

import CloseIcon from "@mui/icons-material/Close"
import useFetchWithMsal from "../hooks/useFetchWithMsal"

const MAP_API_KEY = "AIzaSyAaPmhnA-AynGixIQAq3_z3q9LDPj___u0"
const MAP_ID = "9fca6486d4422eeb"

const BOUNDARY_ID_SUFFIX = "-boundary"

const getColorByType = (type) => {
  switch (type) {
    case "County":
      return "red"
    case "Grape District":
      return "purple"
    case "AVA":
      return "green"
    case "Water District":
      return "#9797cb"
    default:
      return "grey"
  }
}

const render = (status) => {
  if (status === Status.LOADING)
    return (
      <>
        <LinearProgress />
      </>
    )
  if (status === Status.FAILURE) return <h3>{status} ...</h3>
  return null
}

const buildMarkerContent = (block) => {
  const content = document.createElement("div")
  content.classList.add("blockMarker")

  if (block && block.contractstatus?.name !== "Signed") {
    content.classList.add("notsigned")
  }

  content.innerHTML = `
      <div>
        <div style="text-align: center">${
          block.externalContract
            ? block.externalContract.varietyCode
            : block.varietyId
        }</div>
      </div>
    `

  return content
}

function GMap({
  defaultCenter,
  defaultZoom,
  drawMode,
  onDrawFeaturesChange,
  geoEditingBlock,
  defaultMapType,
  blocks,
  selectedBlockID,
  onBlockClick,
  location,
}) {
  const ref = useRef()
  const [map, setMap] = useState(null)
  const [blockMarkers, setBlockMarkers] = useState([])
  const blockMarkersRef = useRef()
  blockMarkersRef.current = blockMarkers
  const centerMarkerRef = useRef()
  const [movedToSelectedId, setMovedToSelectedId] = useState(null)
  const [boundariesExpanded, setBoundariesExpanded] = useState(false)
  const [boundarySelections, setBoundarySelections] = useState({})

  const {
    execute: fetchBoundaries,
    data: boundaries,
    isLoading: boundariesLoading,
  } = useFetchWithMsal()

  const addBlocksToMap = useCallback(
    async (map, blocks) => {
      if (!map || !blocks) {
        return
      }

      let moveMapView = true

      map.data.forEach((f) => {
        // only remove if does not end in -boundary
        const id = f.getProperty("id") + ""
        if (!id.match(BOUNDARY_ID_SUFFIX + "$")) map.data.remove(f)
      })

      if (blockMarkersRef.current && blockMarkersRef.current.length > 0) {
        moveMapView = false
        for (let i = 0; i < blockMarkersRef.current.length; i++) {
          blockMarkersRef.current[i].setMap(null)
        }
      }

      console.log("adding blocks to map")

      const { AdvancedMarkerElement } = await google.maps.importLibrary(
        "marker"
      )

      const newMMarkers = []
      let bounds = new google.maps.LatLngBounds()
      blocks.forEach((block) => {
        let featureArr = []
        if (block.geo) {
          let geo = JSON.parse(block.geo)
          if (geo?.features?.length > 0) {
            let index = 1
            for (let feature of geo.features) {
              let { geometry, type, properties } = feature
              const featarr = map.data.addGeoJson(
                {
                  geometry,
                  type,
                  properties: {
                    ...properties,
                    id: index > 1 ? block.id + "-" + index : block.id,
                    selected: selectedBlockID === block.id ? "true" : null,
                    name: block.description,
                  },
                },
                { idPropertyName: "id" }
              )
              featureArr = featureArr.concat(featarr)
              index++
            }
          }

          if (featureArr.length > 0) {
            let latLngForMarker = null
            featureArr.forEach((feature) => {
              feature.getGeometry().forEachLatLng((latlng) => {
                bounds.extend(latlng)
                latLngForMarker = latlng
              })
            })

            let marker = new AdvancedMarkerElement({
              map,
              position: {
                lat: latLngForMarker.lat(),
                lng: latLngForMarker.lng(),
              },
              content: buildMarkerContent(block),
            })

            marker.addListener("click", (e) => {
              onBlockClick(block.id)
            })

            newMMarkers.push(marker)
          }
        }
      })

      if (!!moveMapView && !selectedBlockID) {
        map.fitBounds(bounds)
      } else {
        if (selectedBlockID) {
          let features = []
          map.data.forEach((f) => {
            const id = f.getProperty("id") + ""
            if (
              id === selectedBlockID + "" ||
              id.startsWith(selectedBlockID + "-")
            ) {
              features.push(f)
            }
          })

          if (features.length > 0) {
            let bounds = new google.maps.LatLngBounds()
            features.forEach((feature) => {
              feature.getGeometry().forEachLatLng((latlng) => {
                bounds.extend(latlng)
              })
            })
            moveMapToBounds(map, bounds, 30, "hybrid")
          }
        }
      }

      setBlockMarkers(newMMarkers)

      map.data.addListener("click", (e) => {
        const blockIdWithDash = e.feature.getProperty("id") + ""
        const id = blockIdWithDash.split("-")[0]
        if (blockIdWithDash.endsWith(BOUNDARY_ID_SUFFIX)) {
          return
        }
        onBlockClick(id)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedBlockID]
  )

  const addDotToMap = useCallback(
    async (map, location) => {
      // show location on the map if there is one, using a marker
      if (!map || !location || !location.lat || !location.lng) {
        return
      }

      if (centerMarkerRef.current) {
        centerMarkerRef.current.setMap(null)
      }

      const { AdvancedMarkerElement } = await google.maps.importLibrary(
        "marker"
      )
      const newMarker = new AdvancedMarkerElement({
        map,
        position: {
          lat: location.lat,
          lng: location.lng,
        },
      })

      centerMarkerRef.current = newMarker

      if (location.centerMap) {
        map.setCenter({
          lat: location.lat,
          lng: location.lng,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    addDotToMap(map, location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (boundariesExpanded && !boundaries) {
      fetchBoundaries("GET", "/api/gr/boundaries")
    }
  }, [boundariesExpanded, fetchBoundaries, boundaries])

  useEffect(() => {
    if (!boundaries || !boundarySelections) {
      return
    }

    console.log("in boundaries useffect")

    // clear the existing boundaries
    map.data.forEach((f) => {
      const id = f.getProperty("id") + ""
      if (!!id && id.endsWith(BOUNDARY_ID_SUFFIX)) {
        map.data.remove(f)
      }
    })

    let individualSelected = false

    const selectedBoundaries = Object.entries(boundarySelections)
      .map(([key, value]) => {
        if (!value.checked) {
          return []
        } else {
          if (value.options.length === 0 || !value.options) {
            return boundaries[key]
          } else {
            individualSelected = true
            return value.options
          }
        }
      })
      .flat()

    if (selectedBoundaries.length === 0) {
      return
    }

    const bounds = new google.maps.LatLngBounds()

    selectedBoundaries.forEach((boundary) => {
      const geo = JSON.parse(boundary.geo)

      const { geometry } = geo
      map.data.addGeoJson({
        geometry,
        type: "Feature",
        properties: {
          id: boundary.id + BOUNDARY_ID_SUFFIX,
          name: boundary.name,
          type: boundary.type,
          color: getColorByType(boundary.type),
        },
      })

      if (geometry.type === "Polygon") {
        geometry.coordinates.forEach((coord) => {
          coord.forEach((c) => {
            bounds.extend({ lat: c[1], lng: c[0] })
          })
        })
      } else if (geometry.type === "MultiPolygon") {
        geometry.coordinates.forEach((coord) => {
          coord.forEach((c) => {
            c.forEach((cc) => {
              bounds.extend({ lat: cc[1], lng: cc[0] })
            })
          })
        })
      }

      if (individualSelected) {
        map.fitBounds(bounds)
      }
    })
  }, [boundaries, boundarySelections, map])

  const moveMapToBounds = useCallback(
    async (map, bounds, padding, changeMapType) => {
      if (!map || !bounds) {
        return
      }

      console.log("moving map to bounds")
      map.fitBounds(bounds, padding)

      if (changeMapType) {
        map.setMapTypeId(changeMapType)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (
      !map ||
      !blocks ||
      !selectedBlockID ||
      movedToSelectedId === selectedBlockID
    ) {
      return
    }

    console.log("updating map from selected block")

    const features = []
    map.data.forEach((feature) => {
      const featureID = feature.getId() + ""
      if (
        featureID === selectedBlockID + "" ||
        featureID.startsWith(selectedBlockID + "-")
      ) {
        features.push(feature)
      }
    })

    if (features.length > 0) {
      let bounds = new google.maps.LatLngBounds()

      features.forEach((feature) => {
        feature.getGeometry().forEachLatLng((latlng) => {
          bounds.extend(latlng)
        })
      })

      // this whole effect may not be needed now that we move map in addBlocksToMap
      moveMapToBounds(map, bounds, 100, "hybrid")
    }

    setMovedToSelectedId(selectedBlockID)

    // this effect may fire too often
  }, [selectedBlockID, map, blocks, moveMapToBounds, movedToSelectedId])

  useEffect(() => {
    if (!ref.current || map) {
      return
    }

    console.log("loading map")

    const mapx = new window.google.maps.Map(ref.current, {
      center: defaultCenter,
      zoom: defaultZoom,
      mapTypeId: defaultMapType || "hybrid",
      controlSize: 24,
      mapId: MAP_ID,
    })

    const dataO = new google.maps.Data({
      map: mapx,
      style: (f) => {
        const selectedProperty = f.getProperty("selected")
        const isSelected =
          selectedProperty !== "undefined" &&
          selectedProperty &&
          selectedProperty !== ""

        const featureID = f.getProperty("id") + ""
        const isBoundary = !!featureID?.endsWith(BOUNDARY_ID_SUFFIX)
        const color = f.getProperty("color")

        return {
          editable: !!drawMode,
          fillOpacity: isBoundary ? 0.3 : 0.05,
          fillColor: isSelected ? "blue" : color || "black",
          strokeColor: isSelected ? "blue" : color || "black",
          strokeOpacity: 1,
          zIndex: isBoundary ? 10 : 20,
        }
      },
      controls: !!drawMode ? ["Polygon"] : null,
    })
    mapx.data = dataO

    if (geoEditingBlock?.geo) {
      let geo = JSON.parse(geoEditingBlock.geo)
      let featureArr = mapx.data.addGeoJson(geo)
      if (featureArr.length > 0) {
        let bounds = new google.maps.LatLngBounds()
        featureArr.forEach((feature) => {
          feature.getGeometry().forEachLatLng((latlng) => {
            bounds.extend(latlng)
          })

          if (!!drawMode) {
            google.maps.event.addListener(feature, "setgeometry", async (e) => {
              let fs = []
              const promises = []
              mapx.data.forEach((feature) => {
                const promise = new Promise((resolve) => {
                  feature.toGeoJson((g) => {
                    fs.push(g)
                    resolve()
                  })
                })
                promises.push(promise)
              })
              Promise.all(promises).then(() => {
                onDrawFeaturesChange(fs)
              })
            })
          }
        })
        mapx.fitBounds(bounds)
      }
    }

    if (!!drawMode) {
      mapx.data.addListener("addfeature", async (e) => {
        let fs = []
        const promises = []
        mapx.data.forEach((feature) => {
          const promise = new Promise((resolve) => {
            feature.toGeoJson((g) => {
              fs.push(g)
              resolve()
            })
          })
          promises.push(promise)
        })
        Promise.all(promises).then(() => {
          onDrawFeaturesChange(fs)
        })

        google.maps.event.addListener(e.feature, "setgeometry", async (e) => {
          let fsu = []
          const promises = []
          mapx.data.forEach((feature) => {
            const promise = new Promise((resolve) => {
              feature.toGeoJson((g) => {
                fs.push(g)
                resolve()
              })
            })
            promises.push(promise)
          })
          Promise.all(promises).then(() => {
            onDrawFeaturesChange(fsu)
          })
        })
      })
    }

    if (location) {
      addDotToMap(mapx, location)
    }

    setMap(mapx)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (ref.current && map && blocks) {
      if (blocks) {
        addBlocksToMap(map, blocks)
      }
    }
  }, [map, blocks, addBlocksToMap])

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <div ref={ref} className="gmap" />
      {!!drawMode && (
        <div
          className="drawModeClearControl"
          style={{ position: "absolute", left: 5, bottom: 5 }}
        >
          <button
            onClick={() => {
              map.data.forEach((f) => {
                map.data.remove(f)
              })
              onDrawFeaturesChange([])
            }}
            style={{
              padding: "3px 5px",
              background: "white",
              border: "none",
              fontSize: "12px",
              cursor: "pointer",
              borderRadius: "5px",
            }}
          >
            CLEAR POLYGONS
          </button>
        </div>
      )}
      {!drawMode && (
        <div
          className="boundaryControl"
          style={{ position: "absolute", right: 2, top: 40, fontSize: "12px" }}
        >
          {boundariesExpanded ? (
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                width: "200px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    padding: "5px",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Boundaries
                </div>
                <IconButton
                  size="small"
                  onClick={() => {
                    setBoundariesExpanded(false)
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  padding: "5px",
                }}
              >
                {boundariesLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  boundaries &&
                  Object.entries(boundaries).map(([key, value]) => (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {key}
                        <Switch
                          size="small"
                          checked={!!boundarySelections[key]?.checked}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              setBoundarySelections({
                                ...boundarySelections,
                                [key]: {
                                  checked: false,
                                  options: [],
                                },
                              })
                            } else {
                              setBoundarySelections({
                                ...boundarySelections,
                                [key]: {
                                  checked: e.target.checked,
                                  options:
                                    boundarySelections[key]?.options || [],
                                },
                              })
                            }
                          }}
                        />
                      </div>
                      {boundarySelections[key]?.checked && (
                        <div style={{ flex: 1 }}>
                          <Autocomplete
                            multiple
                            size="small"
                            disableClearable
                            disablePortal
                            id="combo-box-demo"
                            disableListWrap={true}
                            options={value}
                            sx={{ width: "100%", fontSize: "10px" }}
                            renderInput={(params) => (
                              <TextField
                                placeholder="Search..."
                                variant="standard"
                                size="small"
                                style={{ fontSize: "12px" }}
                                {...params}
                              />
                            )}
                            onChange={(e, value) => {
                              setBoundarySelections({
                                ...boundarySelections,
                                [key]: {
                                  checked: boundarySelections[key]?.checked,
                                  options: value,
                                },
                              })
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            value={boundarySelections[key]?.options || []}
                            getOptionLabel={(option) => option.name}
                            getOptionKey={(option) => option.id}
                            openOnFocus={true}
                          />
                        </div>
                      )}
                    </div>
                  ))
                )}
              </div>
            </div>
          ) : (
            <IconButton
              size="small"
              onClick={() => {
                setBoundariesExpanded(true)
              }}
            >
              <LayersIcon color="primary" />
            </IconButton>
          )}
        </div>
      )}
    </div>
  )
}

export const Map = ({
  drawMode,
  onDrawFeaturesChange,
  geoEditingBlock,
  defaultMapType,
  blocks,
  selectedBlockID,
  onBlockClick,
  location,
}) => {
  const defaultCenter = { lat: 36.612435, lng: -119.484685 }
  const defaultZoom = 7

  return (
    <Wrapper apiKey={MAP_API_KEY} render={render} libraries={["drawing"]}>
      <GMap
        selectedBlockID={selectedBlockID}
        defaultMapType={defaultMapType}
        geoEditingBlock={geoEditingBlock}
        drawMode={!!drawMode}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onDrawFeaturesChange={onDrawFeaturesChange}
        blocks={blocks}
        onBlockClick={onBlockClick}
        location={location}
      />
    </Wrapper>
  )
}
