import { useEffect, useState, useContext } from "react"
import { AuthenticatedTemplate } from "@azure/msal-react"
import Page from "../components/Page"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import { BlockDetail } from "../components/BlockDetail"
import { allPermissions, hasPermission } from "../utils/permissions"
import { PermissionsContext } from "../App"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import { useNavigate } from "react-router-dom"
import { LinearProgress } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import RefreshIcon from "@mui/icons-material/Refresh"
import Dialog from "@mui/material/Dialog"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import { useSearchParams } from "react-router-dom"
import DateRangePickerComponent from "../components/DateRangePicker"

import "../styles/GRDeliveries.css"

const EditProgram = ({ editProgram, handleUpdateProgram, close }) => {
  const [programCode, setProgramCode] = useState("")

  let instructions = ""
  if (editProgram.id) {
    instructions = `Updating Program for just Weigh Tag: ${editProgram.id}`
  } else if (editProgram.wo) {
    if (editProgram.contract) {
      instructions = `Updating Program for all Weigh Tags with WO: ${editProgram.wo} and Contract: ${editProgram.contract}`
    } else {
      instructions = `Updating Program for all Weigh Tags with WO: ${editProgram.wo}`
    }
  }

  return (
    <div>
      {editProgram && (
        <div>
          <div className="head">{instructions}</div>
          <div>
            <div>
              Number of deliveries that will be updated:{" "}
              {editProgram.numberDeliveriesAffected}
            </div>
          </div>
          <div style={{ margin: "20px 0" }}>
            <TextField
              label="New Program Code"
              value={programCode}
              onChange={(e) => setProgramCode(e.target.value)}
            />
            <div>The Program should already be set on the Block!</div>{" "}
          </div>
          <div>
            <Stack direction="row" spacing={1} alignItems={"space-between"}>
              <Button
                color="secondary"
                size="small"
                variant="contained"
                onClick={() => close()}
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={() =>
                  handleUpdateProgram({
                    wo: editProgram.wo,
                    contract: editProgram.contract,
                    id: editProgram.id,
                    programCode: programCode,
                  })
                }
              >
                Update Program
              </Button>
            </Stack>
          </div>
        </div>
      )}
    </div>
  )
}

export const DeliveriesPageBody = ({ showLinks, singleDate, singleBlock }) => {
  const { execute, data, isLoading: listLoading } = useFetchWithMsal()
  const { execute: executeGetBlocks } = useFetchWithMsal()
  const { execute: executeUpdateProgram, isLoading: isUpdating } =
    useFetchWithMsal()

  let [searchParams, setSearchParams] = useSearchParams()
  const blockURLParam = searchParams.get("block")

  const [dateRange, setDateRange] = useState({
    startDate: singleDate
      ? singleDate
      : new Date(new Date().setDate(new Date().getDate() - 7))
          .toISOString()
          .split("T")[0],
    endDate: singleDate ? singleDate : new Date().toISOString().split("T")[0],
  })

  const navigate = useNavigate()

  const [selectedBlock, setSelectedBlock] = useState(null)
  const [editProgram, setEditProgram] = useState(null)

  const [permissions] = useContext(PermissionsContext)
  let hasWritePermission = !!hasPermission(
    permissions,
    allPermissions.GROWER_RELATIONS_WRITE
  )

  const dataURL = blockURLParam
    ? `/api/gr/deliveries?block=${blockURLParam}`
    : `/api/gr/deliveries?startDate=${dateRange.startDate}&endDate=${
        dateRange.endDate
      }${singleBlock ? "&block=" + singleBlock : ""}`

  useEffect(() => {
    execute("GET", dataURL)
  }, [execute, dataURL])

  const onBlockSelect = async (blockID) => {
    if (blockID) {
      if (selectedBlock && selectedBlock.id === blockID) {
        return
      }
      const resp = await executeGetBlocks(
        "GET",
        `/api/gr/blocks?blockID=${blockID}`
      )
      if (resp && resp.length > 0) {
        setSelectedBlock(resp[0])
      }
    } else {
      setSelectedBlock(null)
    }
  }

  const onEdit = (wo, contract, id) => {
    if (!hasWritePermission) {
      return
    }
    if (wo || id) {
      const numberDeliveriesAffected =
        data?.filter((d) => {
          if (wo) {
            if (contract) {
              return d.wo === wo && d.contractId === contract
            } else {
              return d.wo === wo
            }
          } else {
            return d.id === id
          }
        }).length || 0
      setEditProgram({ wo, contract, id, numberDeliveriesAffected })
    }
  }

  const handleUpdateProgram = async (updates) => {
    await executeUpdateProgram("POST", `/api/gr/deliveries/program`, updates)
    setEditProgram(null)
    execute("GET", dataURL)
  }

  return (
    <>
      <div className="GRDeliveries">
        <div className="ribbon">
          <Stack direction="row" spacing={1} alignItems={"center"}>
            <IconButton
              size="small"
              variant="contained"
              color="primary"
              onClick={() => execute("GET", dataURL)}
            >
              <RefreshIcon />
            </IconButton>
            {blockURLParam ? (
              <div>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    searchParams.delete("block")
                    setSearchParams(searchParams)
                  }}
                >
                  CLEAR BLOCK FILTER
                </Button>
              </div>
            ) : (
              <DateRangePickerComponent
                value={dateRange}
                setDateRange={setDateRange}
                disabled={singleDate}
              />
            )}
            <div>{data && data.length + " Deliveries"}</div>
          </Stack>
          {showLinks && (
            <Stack direction="row" spacing={1}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => navigate("/gr")}
                endIcon={<OpenInNewIcon />}
              >
                Blocks
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => navigate("/gr/schedule")}
                endIcon={<CalendarMonthIcon />}
              >
                Schedule
              </Button>
            </Stack>
          )}
        </div>
        {(listLoading || isUpdating) && (
          <div className="loadingBar">
            <LinearProgress />
          </div>
        )}
        <div className="tableCont">
          <table className="ListTable">
            <thead>
              <tr>
                <th>Date</th>
                <th>Location</th>
                <th className="editable">WO</th>
                <th className="editable">Contract</th>
                <th>Variety</th>
                <th className="wt">Weigh Tag</th>
                <th>Tons</th>
                <th>Brix</th>
                <th className="program">Actual Program</th>
                <th className="program editable">Overwrite Program</th>
                <th>Block</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.date}</td>
                    <td>{item.locationCode}</td>
                    <td className="wo editable">
                      <div>
                        <span>{item.wo && item.wo + " "}</span>
                        <button
                          disabled={!hasWritePermission}
                          onClick={() => onEdit(item.wo, null, null)}
                          className="edit"
                        >
                          EDIT
                        </button>
                      </div>
                    </td>
                    <td className="contract editable">
                      <div>
                        <span>
                          {item.contractId
                            ? item.contractId.split("*")[0] + " "
                            : ""}
                        </span>
                        <button
                          disabled={!hasWritePermission}
                          onClick={() =>
                            onEdit(item.wo, item.contractId || null, null)
                          }
                          className="edit"
                        >
                          EDIT
                        </button>
                      </div>
                    </td>
                    <td>{item.varietyCode}</td>
                    <td className="wt">{item.id}</td>
                    <td>{item.tons}</td>
                    <td>{item.brix}</td>
                    <td className="program">{item.assumedProgramCode}</td>
                    <td className="program editable">
                      <div>
                        <span>
                          {item.overwrittenProgramCode &&
                            item.overwrittenProgramCode + " "}
                        </span>
                        <button
                          disabled={!hasWritePermission}
                          onClick={() => onEdit(null, null, item.id)}
                          className="edit"
                        >
                          EDIT
                        </button>
                      </div>
                    </td>
                    <td>
                      {item.externalContract &&
                      item.externalContract.blocks &&
                      item.externalContract.blocks.length > 0 ? (
                        <div
                          className="link"
                          onClick={() =>
                            onBlockSelect(item.externalContract.blocks[0].id)
                          }
                        >
                          {item.externalContract.blocks[0].id}
                        </div>
                      ) : (
                        <div className="error">NONE!</div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {selectedBlock && (
          <div className="BlockDetailCont blockDetailContainer ">
            <BlockDetail
              block={selectedBlock}
              close={() => setSelectedBlock(null)}
              reload={() => onBlockSelect(selectedBlock.id)}
              hasWritePermission={hasWritePermission}
              onBlockEdit={() => {}}
              onGeoEdit={() => {}}
            />
          </div>
        )}
      </div>
      <Dialog
        fullWidth
        open={!!editProgram}
        onClose={() => setEditProgram(null)}
      >
        <Box style={{ padding: "10px" }}>
          {editProgram && (
            <EditProgram
              editProgram={editProgram}
              handleUpdateProgram={handleUpdateProgram}
              close={() => setEditProgram(null)}
            />
          )}
        </Box>
      </Dialog>
    </>
  )
}

export const DeliveriesPage = () => {
  return (
    <AuthenticatedTemplate>
      <Page fullHeight>
        <DeliveriesPageBody showLinks={true} />
      </Page>
    </AuthenticatedTemplate>
  )
}
