import { useContext } from "react"
import Page from "../components/Page"
import { PermissionsContext } from "../App"
import { allPermissions, hasPermission } from "../utils/permissions"
import ManageUsers from "../components/ManageUsers"

export const Admin = () => {
  const [permissions] = useContext(PermissionsContext)

  if (hasPermission(permissions, allPermissions.ADMIN)) {
    return (
      <Page>
        <ManageUsers />
      </Page>
    )
  } else {
    return <Page>NOT ALLOWED</Page>
  }
}
