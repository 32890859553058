import { Button } from "@mui/material"
import LinearProgress from "@mui/material/LinearProgress"
import { Map } from "./Map"
import React, { useState } from "react"

import Stack from "@mui/material/Stack"

export const GeoEditor = ({ block, onCancel, onSave }) => {
  const [features, setFeatures] = useState([])

  const saveGeo = (newFeatures) => {
    let geo = null
    if (newFeatures && newFeatures.length > 0) {
      geo = {
        type: "FeatureCollection",
        features: newFeatures,
      }
      geo = JSON.stringify(geo)
    }
    onSave({ id: block.id, geo: geo })
  }

  return (
    <div className="BlockEditor form">
      <div style={{ fontWeight: "bold" }}>
        {block?.description} -{" "}
        {block?.externalContract?.growerName || block?.growerName}
      </div>
      {false ? (
        <div className="formRow">
          <LinearProgress />
        </div>
      ) : (
        <div>
          {block?.id && (
            <div className="mapCont">
              <Map
                geoEditingBlock={block}
                drawMode={true}
                onDrawFeaturesChange={(f) => setFeatures(f)}
              />
            </div>
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <div>
          {block?.geo && (
            <div>
              <Button
                color="error"
                variant="contained"
                onClick={() => saveGeo(null)}
              >
                REMOVE GEO & SAVE
              </Button>
            </div>
          )}
        </div>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => saveGeo(features)}
            disabled={features.length === 0}
          >
            SAVE
          </Button>
          <Button variant="outline" color="secondary" onClick={onCancel}>
            CANCEL
          </Button>
        </Stack>
      </div>
    </div>
  )
}
