import React, { useState, useEffect } from "react"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import { DataGrid } from "@mui/x-data-grid"
import { Dialog, Box, Button } from "@mui/material"
import { allPermissions } from "../utils/permissions"

// this component should show a list of all permissions with checkboxes
// it should allow to add/remove permissions from a user
const EditUser = ({ user, onCancel, onSave }) => {
  const [tempPermissions, setTempPermissions] = useState(user?.userPermissions)

  const resetToOriginal = () => {
    setTempPermissions(user.userPermissions)
  }

  const onSaveClicked = () => {
    onSave(tempPermissions.map((up) => up.permission_code))
  }

  if (!user) {
    return null
  }
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <Button onClick={onCancel}>CLOSE</Button>
      </div>
      <div>Edit User: {user.name}</div>
      <div style={{ margin: "10px 0" }}>
        {Object.keys(allPermissions).map((key) => {
          const p = allPermissions[key]
          return (
            <div key={p}>
              <input
                style={{ marginRight: "5px" }}
                type="checkbox"
                checked={tempPermissions?.some(
                  (up) => up.permission_code === p
                )}
                onChange={(e) => {
                  if (e.target.checked) {
                    setTempPermissions([
                      ...(tempPermissions || []),
                      { permission_code: p },
                    ])
                  } else {
                    setTempPermissions(
                      tempPermissions?.filter((up) => up.permission_code !== p)
                    )
                  }
                }}
              />
              {p}
            </div>
          )
        })}
      </div>
      <div>
        <Button onClick={resetToOriginal}>Reset to original</Button>
        <Button variant="contained" onClick={() => onSaveClicked()}>
          Save
        </Button>
      </div>
    </div>
  )
}

const ManageUsers = () => {
  const {
    execute: executeGetUsers,
    data: users,
    isLoading: usersLoading,
  } = useFetchWithMsal()
  const { execute: executeUpdateUser, isLoading: isUpdating } =
    useFetchWithMsal()
  const { execute: executeCreateUser, isLoading: isCreating } =
    useFetchWithMsal()

  const [newUser, setNewUser] = useState({ name: "", oid: "" })

  const [userEdit, setUserEdit] = useState(null)

  useEffect(() => {
    if (!users) {
      executeGetUsers("GET", "/api/users")
    }
  }, [executeGetUsers, users])

  const handleCreateUser = async () => {
    if (!newUser.name || !newUser.oid) {
      return
    }
    await executeCreateUser("POST", "/api/users", newUser)
    setNewUser({ name: "", oid: "" })
    executeGetUsers("GET", "/api/users")
  }

  const handleUpdateUser = async (user, permissions) => {
    await executeUpdateUser("POST", `/api/users/${user.oid}`, {
      permissions,
    })
    setUserEdit(null)
    executeGetUsers("GET", "/api/users")
  }

  return (
    <div>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <input
          type="text"
          placeholder="Name"
          value={newUser.name}
          onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="OID"
          value={newUser.oid}
          onChange={(e) => setNewUser({ ...newUser, oid: e.target.value })}
        />
        <Button variant="contained" onClick={handleCreateUser}>
          Create User
        </Button>
        <Button onClick={() => setNewUser({ name: "", oid: "" })}>
          Cancel
        </Button>
      </div>
      <div>
        <DataGrid
          rows={users || []}
          getRowId={(row) => row.oid}
          columns={[
            { field: "oid", headerName: "OID", width: 400 },
            { field: "name", headerName: "Name", width: 300 },
            {
              field: "userPermissions",
              headerName: "Permissions",
              minWidth: 300,
              flex: 1,
              renderCell: ({ row }) => (
                <div onClick={() => setUserEdit({ user: row })}>
                  {row.userPermissions?.length > 0
                    ? `${
                        row.userPermissions.length > 1
                          ? "(" + row.userPermissions.length + ")"
                          : ""
                      }
                      ${row.userPermissions
                        ?.map((up) => up.permission_code)
                        .join(", ")}
                      `
                    : "None"}
                </div>
              ),
            },
          ]}
          loading={usersLoading || isUpdating || isCreating}
          autoHeight
        />
        <Dialog fullWidth open={!!userEdit} onClose={() => setUserEdit(null)}>
          <Box style={{ padding: "10px" }}>
            <EditUser
              user={userEdit?.user}
              onCancel={() => setUserEdit(null)}
              onSave={(permissions) =>
                handleUpdateUser(userEdit?.user, permissions)
              }
            />
          </Box>
        </Dialog>
      </div>
    </div>
  )
}

export default ManageUsers
