import React, { useEffect, useContext, useMemo, useState } from "react"
import Page from "../components/Page"
import { useNavigate, useLocation, useSearchParams } from "react-router-dom"
import { Button, Dialog, Stack } from "@mui/material"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import { allPermissions, hasPermission } from "../utils/permissions"
import { PermissionsContext } from "../App"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { IconButton } from "@mui/material"
import RefreshIcon from "@mui/icons-material/Refresh"
import LinearProgress from "@mui/material/LinearProgress"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import Popover from "@mui/material/Popover"
import Popper from "@mui/material/Popper"
import { TextField } from "@mui/material"
import { FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material"
import PopupState, { bindPopover } from "material-ui-popup-state"
import HelpIcon from "@mui/icons-material/Help"
import { BlockDetail } from "../components/BlockDetail"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import {
  FilterController,
  blockDataToFilterFields,
} from "../components/FilterController"
import { DeliveriesPageBody } from "./GRDeliveries"

import "../styles/GRSchedule.css"

const today = new Date()
today.setHours(0, 0, 0, 0)

const thisWeekMonday = new Date()
thisWeekMonday.setDate(thisWeekMonday.getDate() - thisWeekMonday.getDay() + 1)
thisWeekMonday.setHours(0, 0, 0, 0)

const weeksToShow = 3
const daysToShow = 7 * weeksToShow

const YELLOW = "rgb(207, 243, 6)"

// hard coded list for now, should change later
const crushSites = [
  {
    code: "RE",
    name: "Parlier",
  },
  {
    code: "PS",
    name: "Robert Hall",
  },
]

const stages = [
  {
    key: 1,
    value: "Forecasted",
    color: "rgb(255 198 245)",
  },
  {
    key: 2,
    value: "Released",
    color: "rgb(105 95 243)",
  },
  {
    key: 3,
    value: "Tentative",
    color: "rgb(104 255 165)",
  },
  {
    key: 4,
    value: "Confirmed",
    color: "#aa1e2e",
    fontColor: "white",
  },
]

const groupingChoices = [
  {
    keyAccessor: (block) =>
      block.externalContract
        ? block.externalContract.varietyCode
        : block.varietyId,
    descriptionAccessor: (block) =>
      block.externalContract
        ? block.externalContract.varietyCode
        : block.varietyId,
    name: "Variety",
  },
  {
    keyAccessor: (block) => block.programCode,
    descriptionAccessor: (block) => block.programCode,
    name: "Program",
  },
  {
    keyAccessor: (block) => block.growerName,
    descriptionAccessor: (block) => block.growerName,
    name: "Grower",
  },
  {
    keyAccessor: (block) => block.id,
    descriptionAccessor: (block) => block.description,
    name: "Block",
  },
]

const ScheduleChangePopover = ({
  anchorEl,
  closeSelf,
  values,
  onValueChange,
  popoverData,
  savedValue,
}) => {
  const [editNote, setEditNote] = useState(false)
  const username =
    savedValue && savedValue?.user && savedValue.user
      ? savedValue.user.name
      : null

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <Popover
          {...bindPopover(popupState)}
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={() => closeSelf()}
        >
          <div className="ScheduleChangePopover">
            <div className="content">
              <div>
                {popoverData.date} - {popoverData.blockName}
                {username && (
                  <div>
                    <div>Last Updated by: {username}</div>
                  </div>
                )}
              </div>
              <div>
                <TextField
                  autoFocus
                  fullWidth
                  size="small"
                  type="text"
                  label="Loads"
                  value={
                    values?.loads === null || values?.loads === undefined
                      ? ""
                      : values?.loads
                  }
                  onChange={(e) => {
                    onValueChange("loads", e.target.value)
                  }}
                />
              </div>
              <div>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="stageradio"
                    value={
                      values?.stage
                        ? values.stage.toString()
                        : stages[0].key.toString()
                    }
                    onChange={(e) => {
                      onValueChange("stage", Number(e.target.value))
                    }}
                    name="radio-buttons-group"
                    row
                  >
                    {stages.map((stage) => (
                      <FormControlLabel
                        key={stage.key}
                        value={stage.key.toString()}
                        control={<Radio size="small" />}
                        label={stage.value}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                }}
              >
                {editNote ? (
                  <div>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      label="Note"
                      multiline
                      rows={3}
                      value={values?.comment || ""}
                      onChange={(e) => {
                        onValueChange("comment", e.target.value)
                      }}
                    />
                  </div>
                ) : (
                  values?.comment && (
                    <div
                      className="comment"
                      style={{
                        whiteSpace: "pre-wrap",
                        textDecoration: "italic",
                      }}
                    >
                      {values.comment}
                    </div>
                  )
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      if (editNote) {
                        onValueChange("comment", null)
                      }
                      setEditNote(!editNote)
                    }}
                  >
                    {editNote ? "REMOVE NOTE" : "ADD NOTE"}
                  </Button>
                  <Button
                    onClick={() => {
                      closeSelf()
                    }}
                  >
                    CLOSE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Popover>
      )}
    </PopupState>
  )
}

const getDayTotals = (block, dateString, isPast, unsavedChanges) => {
  let unsavedValue = null
  if (unsavedChanges !== undefined) {
    unsavedValue = unsavedChanges
  }

  const savedValue = block?.schedules?.find(
    (schedule) => schedule.date === dateString
  )

  const isChanged =
    unsavedValue !== null &&
    unsavedValue.newValue !== null &&
    (unsavedValue.newValue.loads !== savedValue?.loads ||
      unsavedValue.newValue.stage !== savedValue?.stage ||
      unsavedValue.newValue.comment !== savedValue?.comment)

  let pastLoads = null
  let pastTons = null
  if (isPast && block?.externalContract && block?.deliveries) {
    const deliveries = block?.deliveries.filter(
      (delivery) => delivery.date === dateString
    )

    pastLoads = deliveries.reduce(
      (total, delivery) => total + delivery.loads,
      0
    )
    pastTons = deliveries.reduce((total, delivery) => total + delivery.tons, 0)
  }

  const futureDateVal =
    unsavedValue !== null
      ? unsavedValue.newValue?.loads
      : savedValue === null ||
        savedValue === undefined ||
        savedValue.loads === undefined ||
        savedValue.loads === 0
      ? 0
      : savedValue.loads

  return {
    isChanged,
    pastLoads,
    pastTons,
    futureDateVal,
    savedValue,
    unsavedValue,
  }
}

const renderRow = (
  block,
  unsavedChanges,
  popoverData,
  setPopoverData,
  selectedBlockID,
  onBlockSelect,
  dates,
  setUnsavedChanges,
  setDeliveryView,
  groupHeaderRow,
  isSingleRowGroup
) => {
  const estimated = groupHeaderRow
    ? groupHeaderRow.estimated
    : block.estimatedTons
  const scheduled = groupHeaderRow
    ? groupHeaderRow.scheduled
    : block.scheduledTons
  const delivered = groupHeaderRow
    ? groupHeaderRow.delivered
    : block.deliveredTons
  const total = groupHeaderRow ? groupHeaderRow.total : block.totalTons

  const hasUnsavedChanges = block?.thisBlockUnsavedChanges?.length > 0

  const tonsOverEstimate = total - estimated || 0

  const percentOverContract =
    (block?.tonsOverContract / block?.contractedTons) * 100

  const percentOverEstimate = (tonsOverEstimate / estimated) * 100

  return (
    <tr
      key={`${block?.id}${block?.programCode}${block?.locationCode}
        ${groupHeaderRow ? groupHeaderRow.key : ""}
      `}
      className={`blockRow ${
        block &&
        block?.id === popoverData?.blockId &&
        block?.programCode === popoverData?.programCode &&
        block?.locationCode === popoverData?.locationCode
          ? "hasPopover"
          : ""
      } ${groupHeaderRow ? "groupHeaderRow" : ""}
                  ${isSingleRowGroup ? "singleRowGroup" : ""}
                  ${block && block?.id === selectedBlockID ? "isDetailed" : ""}
                  ${block?.deliverystatus === "Completed" ? "Completed" : ""}
                  ${block?.deliverystatus === "Released" ? "Released" : ""}
                  `}
    >
      <th>
        <div className="blockCellCont">
          <>
            <div
              className="blockAttributes"
              onClick={() => block && onBlockSelect(block.id)}
            >
              {groupHeaderRow ? (
                <div className="description">{groupHeaderRow.description}</div>
              ) : (
                <>
                  <div className="top">
                    <div className="description" style={{ marginRight: "3px" }}>
                      {block.description}
                    </div>
                    <div className="grower">
                      {block.externalContract?.growerName || block.growerName}
                    </div>
                  </div>
                  <div className="bottom">
                    <div
                      className="locationCode"
                      style={{ marginRight: "3px" }}
                    >
                      {block.locationCode}
                    </div>
                    <div className="var" style={{ marginRight: "3px" }}>
                      {block.programCode}
                    </div>
                    <div className="var" style={{ marginRight: "3px" }}>
                      {`(${
                        block.externalContract
                          ? block.externalContract.varietyCode
                          : block.varietyId
                      })`}
                    </div>
                    <div className="contract" style={{ marginRight: "3px" }}>
                      {block.externalContract && block.externalContract.id
                        ? block.externalContract.id.split("*")[0]
                        : ""}
                    </div>
                    <div className="grower">{block.growerID}</div>
                  </div>
                </>
              )}
            </div>
            <div className="blockStats">
              <div
                className={`${
                  block && percentOverContract > 0 && percentOverContract <= 10
                    ? "warning"
                    : block && percentOverContract > 10
                    ? "error"
                    : ""
                }`}
              >
                <div className="label">{block ? "Contract" : ""}</div>
                <div className="value">
                  {block?.contractedTons && block?.contractedTons.toFixed(0)}
                </div>
              </div>
              <div
                className={`${
                  block && percentOverEstimate > 0 && percentOverEstimate <= 10
                    ? "warning"
                    : block && percentOverEstimate > 10
                    ? "error"
                    : ""
                }`}
              >
                <div className="label">Estimate</div>
                <div className="value">{estimated.toFixed(0)}</div>
              </div>
              <div>
                <div className="label">Delivered</div>
                <div className="value">{delivered.toFixed(1)}</div>
              </div>
              <div className={`${hasUnsavedChanges ? "unsaved" : ""}`}>
                <div className="label">Scheduled</div>
                <div className="value">{scheduled.toFixed(0)}</div>
              </div>
              <div>
                <div className="label">Total</div>
                <div className="value">{total.toFixed(0)}</div>
              </div>
            </div>
          </>
        </div>
      </th>
      {dates.map((date) => {
        const isToday = date.toDateString() === today.toDateString()
        const isWeekend = date.getDay() === 0 || date.getDay() === 6

        const isPast = date < today
        const month = (date.getMonth() + 1).toString().padStart(2, "0")
        const day = date.getDate().toString().padStart(2, "0")
        const dateString = `${date.getFullYear().toString()}-${month}-${day}`

        const thisCellID = groupHeaderRow
          ? `${groupHeaderRow.key}*${dateString}`
          : `${block.id}*${dateString}*${block.programCode}*${block.locationCode}`

        const {
          isChanged,
          pastLoads,
          pastTons,
          futureDateVal,
          savedValue,
          unsavedValue,
        } = getDayTotals(block, dateString, isPast, unsavedChanges[thisCellID])

        let groupDayTotals = null
        if (groupHeaderRow) {
          groupDayTotals = groupHeaderRow.blocks.reduce(
            (totals, block) => {
              const blockDayTotals = getDayTotals(
                block,
                dateString,
                isPast,
                unsavedChanges[
                  block.id +
                    "*" +
                    dateString +
                    "*" +
                    block.programCode +
                    "*" +
                    block.locationCode
                ]
              )

              return {
                scheduled: totals.scheduled + blockDayTotals.futureDateVal,
                pastLoads: totals.pastLoads + blockDayTotals.pastLoads,
                pastTons: totals.pastTons + blockDayTotals.pastTons,
              }
            },
            {
              scheduled: 0,
              pastLoads: 0,
              pastTons: 0,
            }
          )
        }

        const scheduledValue = groupHeaderRow
          ? groupDayTotals.scheduled
          : futureDateVal
        const pastTonsValue = groupHeaderRow
          ? groupDayTotals.pastTons
          : pastTons
        const pastLoadsValue = groupHeaderRow
          ? groupDayTotals.pastLoads
          : pastLoads

        const onValueChange = (fieldKey, newValue) => {
          let newValueToUse = newValue
          if (fieldKey === "loads") {
            const inputValue = newValue.trim()
            const intValue = parseInt(inputValue)
            newValueToUse = ""
            if (!isNaN(intValue)) {
              newValueToUse = Math.min(intValue, 100)
            }
          }

          setUnsavedChanges({
            ...unsavedChanges,
            [thisCellID]: {
              newValue: {
                loads: 0,
                ...savedValue,
                ...unsavedValue?.newValue,
                [fieldKey]: newValueToUse,
              },
              savedValue,
              blockId: block?.id,
              date: dateString,
              locationCode: block?.locationCode,
              programCode: block?.programCode,
            },
          })
        }

        const currentStageKey =
          unsavedValue?.newValue?.stage || savedValue?.stage
        const currentStage = stages.find(
          (stage) => stage.key === currentStageKey
        )

        return (
          <td
            className={`gridCell dateCell ${isToday ? "isToday" : ""} ${
              isWeekend ? "isWeekend" : ""
            } ${isPast ? "isPast" : ""} ${isChanged ? "isChanged" : ""} ${
              dateString === popoverData?.date ? "hasPopover" : ""
            }`}
            key={date}
          >
            {isPast ? (
              <div
                className="delCont"
                onClick={() => {
                  if (pastLoads !== null && pastLoads > 0) {
                    setDeliveryView({
                      block,
                      date: dateString,
                    })
                  }
                }}
              >
                {pastLoadsValue !== null &&
                  pastLoadsValue > 0 &&
                  `${pastLoadsValue}/${pastTonsValue.toFixed(1)}`}
              </div>
            ) : (
              <input
                onDoubleClick={(e) => {
                  setPopoverData({
                    anchorEl: e.currentTarget,
                    cellID: thisCellID,
                    blockId: block?.id,
                    date: dateString,
                    locationCode: block?.locationCode,
                    programCode: block?.programCode,
                    blockName: block?.description,
                    savedValue: savedValue,
                  })
                }}
                disabled={isPast || groupHeaderRow}
                id={thisCellID}
                type="text"
                value={scheduledValue > 0 ? scheduledValue : ""}
                onChange={(e) => {
                  onValueChange("loads", e.target.value)
                }}
                style={{
                  backgroundColor:
                    !isPast &&
                    currentStage &&
                    futureDateVal !== "" &&
                    futureDateVal !== 0
                      ? currentStage.color
                      : "auto",
                  color:
                    !isPast &&
                    !isChanged &&
                    currentStage &&
                    currentStage.fontColor
                      ? currentStage.fontColor
                      : "black",
                }}
              />
            )}
            {popoverData?.cellID === thisCellID && (
              <ScheduleChangePopover
                anchorEl={popoverData.anchorEl}
                closeSelf={() => setPopoverData(null)}
                values={{
                  ...savedValue,
                  ...unsavedValue?.newValue,
                }}
                onValueChange={onValueChange}
                popoverData={popoverData}
                savedValue={savedValue}
              />
            )}
            {(savedValue?.comment || unsavedValue?.newValue?.comment) && (
              <div className="comment"></div>
            )}
          </td>
        )
      })}
    </tr>
  )
}

const ScheduleGrid = ({
  unsavedChanges,
  setUnsavedChanges,
  dates,
  sortedBlockData,
  sortDay,
  setSortDays,
  onBlockSelect,
  selectedBlockID,
  contractSummaries,
  groupedBlockData,
}) => {
  const [popoverData, setPopoverData] = useState(null)
  const [deliveryView, setDeliveryView] = useState(null)

  return (
    <div className="tableCont ScheduleGrid">
      <table>
        <thead>
          <tr>
            <th>
              <div className="blockCellCont"></div>
            </th>
            {dates.map((date) => {
              const daysOfWeek = [
                "Sun",
                "Mon",
                "Tue",
                "Wed",
                "Thu",
                "Fri",
                "Sat",
              ]
              const dayOfWeek = daysOfWeek[date.getDay()]
              const formattedDate = `${(date.getMonth() + 1).toString()}/${date
                .getDate()
                .toString()}`
              const isToday = date.toDateString() === today.toDateString()
              const isWeekend = dayOfWeek === "Sat" || dayOfWeek === "Sun"
              const isPast = date < today
              const month = (date.getMonth() + 1).toString().padStart(2, "0")
              const day = date.getDate().toString().padStart(2, "0")
              const dateString = `${date
                .getFullYear()
                .toString()}-${month}-${day}`

              const daysUnsavedChanges =
                !isPast &&
                Object.keys(unsavedChanges)
                  .map((key) => unsavedChanges[key])
                  .filter((change) => change.date === dateString)
              const dayScheduledLoadsInBlockSchedulesButNotInUnsavedChanges =
                !isPast &&
                sortedBlockData
                  .filter((block) => {
                    return !Object.keys(unsavedChanges).includes(
                      block.id +
                        "*" +
                        dateString +
                        "*" +
                        block.programCode +
                        "*" +
                        block.locationCode
                    )
                  })
                  .reduce((total, block) => {
                    const schedule = block.schedules.find(
                      (schedule) => schedule.date === dateString
                    )
                    return total + (schedule ? schedule.loads : 0)
                  }, 0)
              const dayScheduledLoads =
                !isPast &&
                daysUnsavedChanges.reduce(
                  (total, change) =>
                    total + parseInt(change.newValue?.loads || 0),
                  0
                ) + dayScheduledLoadsInBlockSchedulesButNotInUnsavedChanges
              const dayDeliveredLoads = sortedBlockData.reduce(
                (total, block) => {
                  const delivery = block.deliveries.find(
                    (delivery) => delivery.date === dateString
                  )
                  return total + (delivery ? delivery.loads : 0)
                },
                0
              )
              return (
                <th
                  className={`dateCell ${isToday ? "isToday" : ""} ${
                    isWeekend ? "isWeekend" : ""
                  } ${isPast ? "isPast" : ""} 
                  ${dateString === popoverData?.date ? "hasPopover" : ""}
                  ${daysUnsavedChanges.length > 0 ? "hasUnsavedChanges" : ""}
                  `}
                  key={date}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSortDays(date)
                  }}
                >
                  <div>
                    <div className="dayOfWeek">{dayOfWeek}</div>
                    <div className="date">{formattedDate}</div>
                    <div className="totals">
                      <div>
                        Total: {isPast ? dayDeliveredLoads : dayScheduledLoads}
                      </div>
                    </div>
                    <div className="sortIndicator">
                      {[sortDay]
                        .map((d) => d.getTime())
                        .includes(date.getTime()) ? (
                        <span>&#x2193;</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {groupedBlockData &&
            groupedBlockData.map((group) => (
              <React.Fragment key={group.key || "x"}>
                {!!!group.noGroup &&
                  renderRow(
                    null,
                    unsavedChanges,
                    popoverData,
                    setPopoverData,
                    selectedBlockID,
                    onBlockSelect,
                    dates,
                    setUnsavedChanges,
                    setDeliveryView,
                    {
                      description: group.description,
                      key: group.key,
                      scheduled: group.totals.scheduled,
                      delivered: group.totals.delivered,
                      contracted: group.totals.contracted,
                      estimated: group.totals.estimated,
                      total: group.totals.total,
                      blocks: group.blocks,
                    },
                    false
                  )}
                {group.blocks?.map((block) =>
                  renderRow(
                    block,
                    unsavedChanges,
                    popoverData,
                    setPopoverData,
                    selectedBlockID,
                    onBlockSelect,
                    dates,
                    setUnsavedChanges,
                    setDeliveryView,
                    null,
                    group.blocks.length === 1
                  )
                )}
              </React.Fragment>
            ))}
        </tbody>
      </table>
      {deliveryView && (
        <Dialog
          fullWidth
          maxWidth="xl"
          open={!!deliveryView}
          onClose={() => setDeliveryView(null)}
        >
          <div
            style={{
              height: "90vh",
            }}
          >
            <DeliveriesPageBody
              showLinks={false}
              singleDate={deliveryView.date}
              singleBlock={deliveryView.block.id}
            />
          </div>
        </Dialog>
      )}
    </div>
  )
}
export const GRSchedule = () => {
  const navigate = useNavigate()
  const { state: navState } = useLocation()
  const navFilterState = navState?.filter || {}
  const [permissions] = useContext(PermissionsContext)
  const [showHelp, setShowHelp] = React.useState(false)
  const [selectedBlock, setSelectedBlock] = useState(null)
  const { execute: executeGetBlockDetail } = useFetchWithMsal()
  let [searchParams, setSearchParams] = useSearchParams()
  const [blockSearchInput, setBlockSearchInput] = useState("")

  const blockURLParam = searchParams.get("block")

  const onBlockSelect = async (blockID) => {
    if (blockID) {
      if (selectedBlock && selectedBlock.id === blockID) {
        return
      }
      const resp = await executeGetBlockDetail(
        "GET",
        `/api/gr/blocks?blockID=${blockID}`
      )
      if (resp && resp.length > 0) {
        setSelectedBlock(resp[0])
      }
    } else {
      setSelectedBlock(null)
    }
  }

  const [crushSite, setCrushSite] = React.useState("RE")
  const [completedFilter, setCompletedFilter] = React.useState(false)
  const [scheduledFilter, setScheduledFilter] = React.useState(false)

  let hasWritePermission = !!hasPermission(
    permissions,
    allPermissions.GROWER_RELATIONS_WRITE
  )

  const [blockDataState, setBlockDataState] = React.useState([])

  const {
    execute: executeGetBlocks,
    data: blockData,
    isLoading: dataIsLoading,
  } = useFetchWithMsal()
  const { execute: executePostSchedule, isLoading: dataIsPosting } =
    useFetchWithMsal()

  const { execute: executeLoadMD, data: md } = useFetchWithMsal()

  const [filterState, setFilterState] = React.useState(navFilterState)

  const [unsavedChanges, setUnsavedChanges] = React.useState({})
  const unsavedChangesCount = Object.keys(unsavedChanges)
    .map((key) => unsavedChanges[key])
    .filter((change) => {
      return (
        (change.newValue?.loads || "") !== (change.savedValue?.loads || "") ||
        (change.newValue?.stage || "") !== (change.savedValue?.stage || "") ||
        (change.newValue?.comment || "") !== (change.savedValue?.comment || "")
      )
    }).length

  const [startDate, setStartDate] = React.useState(thisWeekMonday)

  const [sortDay, setSortDays] = React.useState(today)

  const [groupingChoice, setGroupingChoice] = React.useState(null)

  const dates = []
  for (let i = 0; i < daysToShow; i++) {
    const date = new Date(startDate)
    date.setDate(startDate.getDate() + i)
    dates.push(date)
  }

  const mergeNewSchedules = (newSchedules) => {
    const newBlockData = blockDataState.map((block) => {
      const newBlock = { ...block }
      newBlock.schedules = newSchedules.filter(
        (schedule) =>
          schedule.blockId === block.id &&
          schedule.programCode === block.programCode &&
          schedule.locationCode === block.locationCode
      )
      return newBlock
    })
    setBlockDataState(newBlockData)
  }

  useEffect(() => {
    if (!blockData) {
      executeGetBlocks("GET", "/api/gr/blocks/schedulebyprogram")
    }
  }, [executeGetBlocks, blockData])

  useEffect(() => {
    if (!md) {
      executeLoadMD("GET", "/api/gr/md?resources=locations")
    }
  }, [executeLoadMD, md])

  useEffect(() => {
    if (blockData) {
      setBlockDataState(blockData)
    }
  }, [blockData])

  const filterFields = useMemo(
    () => blockDataToFilterFields(blockDataState),
    [blockDataState]
  )

  const scheduledFilteredBlockData = useMemo(() => {
    return scheduledFilter
      ? blockDataState.filter((block) => {
          return (
            block.schedules?.filter((schedule) => {
              return schedule.loads > 0
            }).length > 0
          )
        })
      : blockDataState
  }, [blockDataState, scheduledFilter])

  const completedFilteredBlockData = useMemo(() => {
    return completedFilter
      ? scheduledFilteredBlockData?.filter((block) => {
          return block.deliverystatus === "Completed"
        })
      : scheduledFilteredBlockData?.filter((block) => {
          return block.deliverystatus !== "Completed"
        })
  }, [scheduledFilteredBlockData, completedFilter])

  const locationFilteredBlockData = useMemo(() => {
    return crushSite
      ? completedFilteredBlockData?.filter(
          (block) => block.locationCode === crushSite
        )
      : completedFilteredBlockData
  }, [completedFilteredBlockData, crushSite])

  const filteredBlockData = useMemo(() => {
    if (blockURLParam) {
      return locationFilteredBlockData?.filter(
        (block) => block.id + "" === blockURLParam
      )
    }
    return filterFields === null
      ? locationFilteredBlockData
      : locationFilteredBlockData?.filter((block) => {
          let show = true
          Object.entries(filterState).forEach(([fieldKey, value]) => {
            if (!value || value.length === 0) {
              return
            }

            const filterField = filterFields.find(
              (f) => f.fieldKey === fieldKey
            )
            const fieldValue = filterField.getValueKey(block)

            if (filterField.multi) {
              if (
                !value.map((x) => x.key).some((x) => fieldValue.includes(x))
              ) {
                show = false
                return
              }
            } else {
              if (!value.map((x) => x.key).includes(fieldValue)) {
                show = false
                return
              }
            }
          })
          return show
        })
  }, [filterFields, filterState, locationFilteredBlockData, blockURLParam])

  const searchedBlockData = useMemo(() => {
    if (!blockSearchInput) {
      return filteredBlockData
    }
    return filteredBlockData?.filter((block) => {
      const searchInput = blockSearchInput.toLowerCase()
      return (
        block?.description?.toLowerCase().includes(searchInput) ||
        block?.growerName?.toLowerCase().includes(searchInput) ||
        block?.programCode?.toLowerCase().includes(searchInput) ||
        block?.variety?.description.toLowerCase().includes(searchInput) ||
        block?.externalContract?.id?.toLowerCase().includes(searchInput) ||
        block?.externalContract?.varietyCode.toLowerCase().includes(searchInput)
      )
    })
  }, [blockSearchInput, filteredBlockData])

  const sortedBlockData = useMemo(() => {
    let blocksWithSortingField = searchedBlockData?.map((block) => {
      const aScheduledLoads = block.schedules.find(
        (schedule) => schedule.date === sortDay.toISOString().split("T")[0]
      )?.loads

      const aDeliveredLoads =
        block.deliveries?.filter(
          (delivery) => delivery.date === sortDay.toISOString().split("T")[0]
        ).length || 0

      const aTotalLoads = (aScheduledLoads || 0) + aDeliveredLoads

      return {
        ...block,
        sortLoads: aTotalLoads,
      }
    })

    return blocksWithSortingField?.sort((a, b) => {
      return b.sortLoads - a.sortLoads
    })
  }, [searchedBlockData, sortDay])

  const contractSummaries = useMemo(() => {
    const contractSummaries = {}
    sortedBlockData.forEach((block) => {
      if (block.externalContract && block.externalContract.id) {
        const contractID = block.externalContract.id
        if (!contractSummaries[contractID]) {
          contractSummaries[contractID] = {
            contractID,
            contractedTons:
              block.externalContract.contractTons || block.contractTons || 0,
            deliveredTons: 0,
            scheduledTons: 0,
          }
        }

        const unsavedChangesForThisBlock = Object.keys(unsavedChanges)
          .map((key) => unsavedChanges[key])
          .filter(
            (change) =>
              change.blockId === block.id &&
              change.locationCode === block.locationCode &&
              change.programCode === block.programCode &&
              (change.newValue || "") !== (change.savedValue || "")
          )

        const scheduledLoadsInUnsavedChanges =
          unsavedChangesForThisBlock.reduce(
            (total, change) => total + parseInt(change.newValue?.loads || 0),
            0
          )
        const scheduledLoadsInBlockSchedulesButNotInUnsavedChanges =
          block?.schedules &&
          block?.schedules
            .filter((schedule) => {
              return !Object.keys(unsavedChanges).includes(
                block.id +
                  "*" +
                  schedule.date +
                  "*" +
                  block.programCode +
                  "*" +
                  block.locationCode
              )
            })
            .reduce((total, schedule) => total + schedule.loads, 0)
        const scheduledLoads =
          scheduledLoadsInUnsavedChanges +
          scheduledLoadsInBlockSchedulesButNotInUnsavedChanges

        const scheduledTons = scheduledLoads * 23

        const contractSummary = contractSummaries[contractID]
        const deliveredTons = block.deliveries.reduce(
          (total, delivery) => total + delivery.tons,
          0
        )
        contractSummary.deliveredTons += deliveredTons
        contractSummary.scheduledTons += scheduledTons
      }
    })
    return contractSummaries
  }, [sortedBlockData, unsavedChanges])

  const groupedBlockData = useMemo(() => {
    const grouped = {}
    if (!groupingChoice) {
      grouped["none"] = {
        noGroup: true,
        blocks: [],
        totals: {
          scheduled: 0,
          delivered: 0,
          contracted: 0,
          estimated: 0,
          total: 0,
          sortLoads: 0,
        },
      }
    }
    sortedBlockData.forEach((block) => {
      const key = groupingChoice?.keyAccessor(block) || "none"
      if (!grouped[key]) {
        grouped[key] = {
          key,
          description: groupingChoice.descriptionAccessor(block),
          blocks: [],
          totals: {
            scheduled: 0,
            delivered: 0,
            contracted: 0,
            estimated: 0,
            total: 0,
            sortLoads: 0,
          },
        }
      }

      const deliveredTons =
        block?.deliveries?.reduce(
          (total, delivery) => total + delivery.tons,
          0
        ) || 0

      const thisBlockUnsavedChanges =
        block &&
        Object.keys(unsavedChanges)
          .map((key) => unsavedChanges[key])
          .filter(
            (change) =>
              change.blockId === block.id &&
              change.locationCode === block.locationCode &&
              change.programCode === block.programCode &&
              (change.newValue || "") !== (change.savedValue || "")
          )

      const scheduledLoadsInUnsavedChanges = thisBlockUnsavedChanges?.reduce(
        (total, change) => total + parseInt(change.newValue?.loads || 0),
        0
      )
      const scheduledLoadsInBlockSchedulesButNotInUnsavedChanges =
        block?.schedules &&
        block?.schedules
          .filter((schedule) => {
            return !Object.keys(unsavedChanges).includes(
              block.id +
                "*" +
                schedule.date +
                "*" +
                block.programCode +
                "*" +
                block.locationCode
            )
          })
          .reduce((total, schedule) => total + schedule.loads, 0)
      const scheduledLoads =
        scheduledLoadsInUnsavedChanges +
        scheduledLoadsInBlockSchedulesButNotInUnsavedChanges

      const scheduledTons = scheduledLoads * 23
      const contractedTons =
        block?.externalContract?.contractTons || block?.contractTons || 0
      const estimatedTons = Math.round(block?.programTons) || 0

      const totalTons = scheduledTons + deliveredTons

      const contractSummary = contractSummaries[block?.externalContract?.id]

      const tonsOverContract = contractSummary
        ? contractSummary.deliveredTons +
          contractSummary.scheduledTons -
          contractSummary.contractedTons
        : 0

      grouped[key].blocks.push({
        ...block,
        scheduledTons,
        contractedTons,
        estimatedTons,
        totalTons,
        deliveredTons,
        thisBlockUnsavedChanges,
        tonsOverContract,
      })
      grouped[key].totals.scheduled += scheduledTons
      grouped[key].totals.delivered += deliveredTons
      grouped[key].totals.contracted += contractedTons
      grouped[key].totals.estimated += estimatedTons
      grouped[key].totals.total += totalTons
      grouped[key].totals.sortLoads += block.sortLoads
    })

    return Object.values(grouped).sort((a, b) => {
      if (a.totals.sortLoads !== b.totals.sortLoads) {
        return b.totals.sortLoads - a.totals.sortLoads
      } else {
        return a.description.localeCompare(b.description)
      }
    })
  }, [sortedBlockData, groupingChoice, unsavedChanges, contractSummaries])

  return (
    <Page fullHeight className="GRSchedule">
      <div className="cont">
        <div className="gr-schedule-header ribbon">
          <Stack direction="row" spacing={1} alignItems={"center"}>
            <IconButton
              size="small"
              variant="contained"
              color="primary"
              onClick={() =>
                executeGetBlocks("GET", "/api/gr/blocks/schedulebyprogram")
              }
            >
              <RefreshIcon />
            </IconButton>
            <div style={{ display: "flex" }} className="dateSelector">
              <IconButton
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  const previousWeek = new Date(startDate)
                  previousWeek.setDate(startDate.getDate() - 7)
                  setStartDate(previousWeek)
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <div className="dateLabel" style={{ margin: "0 10px" }}>
                {startDate.toLocaleDateString()}
              </div>
              <IconButton
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  const nextWeek = new Date(startDate)
                  nextWeek.setDate(startDate.getDate() + 7)
                  setStartDate(nextWeek)
                }}
              >
                <ArrowForwardIcon />
              </IconButton>
              <Button
                size="small"
                color="primary"
                className="xlonly"
                onClick={() => {
                  setStartDate(thisWeekMonday)
                }}
                disabled={
                  startDate.toDateString() === thisWeekMonday.toDateString()
                }
              >
                THIS WEEK
              </Button>
            </div>
            <div>
              <FilterController
                fields={filterFields}
                values={filterState}
                onChange={(fieldKey, newState) =>
                  setFilterState({
                    ...filterState,
                    [fieldKey]: newState,
                  })
                }
                clearAll={() => setFilterState({})}
              />
            </div>
            <div>
              <div className="crushSiteSelector xlonly">
                <ToggleButtonGroup
                  value={crushSite || ""}
                  exclusive
                  onChange={(e, value) =>
                    setCrushSite(value === "" ? null : value)
                  }
                  size="small"
                >
                  <ToggleButton size="small" key={""} value={""}>
                    All
                  </ToggleButton>
                  {(md?.locations || crushSites).map((site) => (
                    <ToggleButton
                      size="small"
                      key={site.code}
                      value={site.code}
                    >
                      {site.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            </div>
            <div className="completedStatusSelector xlonly">
              <ToggleButtonGroup
                value={completedFilter ? "completed" : ""}
                exclusive
                onChange={(e, value) => setCompletedFilter(!completedFilter)}
                onClick={() => setCompletedFilter(!completedFilter)}
                size="small"
              >
                <ToggleButton size="small" key={""} value={true}>
                  {completedFilter ? "View Non-Completed" : "View Completed"}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className="scheduledSelector xlonly">
              <ToggleButtonGroup
                value={scheduledFilter ? "completed" : ""}
                exclusive
                onChange={(e, value) => setScheduledFilter(!scheduledFilter)}
                onClick={() => setScheduledFilter(!scheduledFilter)}
                size="small"
              >
                <ToggleButton size="small" key={""} value={true}>
                  {scheduledFilter ? "View All" : "Only Scheduled"}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className="search xlonly">
              <TextField
                size="small"
                value={blockSearchInput}
                onChange={(e) => setBlockSearchInput(e.target.value)}
                id="outlined-search"
                type="search"
                placeholder="Search Blocks"
              />
            </div>
            <div>
              <ToggleButtonGroup
                value={groupingChoice?.idx.toString() || ""}
                exclusive
                onChange={(e, value) => {
                  if (value === null) {
                    // if press selected, then value is null for some reason
                    return
                  }

                  setGroupingChoice(
                    value === ""
                      ? null
                      : { ...groupingChoices[parseInt(value)], idx: value }
                  )
                }}
                size="small"
              >
                <ToggleButton size="small" key={""} value={""}>
                  NO GROUP
                </ToggleButton>
                {Object.values(groupingChoices).map((choice, idx) => (
                  <ToggleButton
                    size="small"
                    key={choice.name}
                    value={idx.toString()}
                  >
                    {choice.name}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
            {blockURLParam && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => {
                  searchParams.delete("block")
                  setSearchParams(searchParams)
                }}
              >
                {filteredBlockData && filteredBlockData.length > 0
                  ? "CLEAR FILTER: " + filteredBlockData[0].description
                  : "CLEAR FILTER: " + blockURLParam}
              </Button>
            )}
          </Stack>
          <Stack direction="row" spacing={1} alignItems={"center"}>
            <IconButton
              size="small"
              variant="contained"
              onClick={(e) => setShowHelp(!!showHelp ? null : e.currentTarget)}
            >
              <HelpIcon />
            </IconButton>
            <Popper
              open={!!showHelp}
              anchorEl={showHelp}
              placement="bottom-end"
            >
              <div className="schedulehelp">
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Colors
                </div>
                <div className="colorexplainer">
                  <div className="rowx">
                    <div
                      className="colorsquare"
                      style={{
                        backgroundColor: YELLOW,
                      }}
                    ></div>
                    <div className="explanation">
                      Unsaved Edit - You need to press the SAVE CHANGES button
                      at top.
                    </div>
                  </div>
                  {stages.map((stage) => (
                    <div className="rowx" key={stage.key}>
                      <div
                        className="colorsquare"
                        style={{
                          backgroundColor: stage.color,
                          color: stage.fontColor,
                        }}
                      ></div>
                      <div className="explanation">
                        Pick Status = {stage.value}
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Notes
                </div>
                <div>
                  Double Click on a cell to change the status or add a comment.
                </div>
              </div>
            </Popper>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={() =>
                navigate("/gr", { state: { filter: filterState } })
              }
              endIcon={<OpenInNewIcon />}
            >
              BLOCKS
            </Button>
          </Stack>
        </div>
        {unsavedChangesCount > 0 && (
          <div style={{ display: "flex" }} className="changeActions">
            <div className="changeCount">
              {unsavedChangesCount > 0
                ? unsavedChangesCount > 1
                  ? `${unsavedChangesCount} CHANGES`
                  : "1 CHANGE"
                : ""}
            </div>
            {unsavedChangesCount > 0 && (
              <>
                <Button
                  size="small"
                  color="success"
                  variant="contained"
                  onClick={async () => {
                    if (!hasWritePermission) {
                      alert("You do not have permission to save changes.")
                      return
                    }
                    const body = Object.keys(unsavedChanges)
                      .map((key) => unsavedChanges[key])
                      .filter((change) => {
                        return (
                          (change.newValue?.loads || "") !==
                            (change.savedValue?.loads || "") ||
                          (change.newValue?.stage || "") !==
                            (change.savedValue?.stage || "") ||
                          (change.newValue?.comment || "") !==
                            (change.savedValue?.comment || "")
                        )
                      })
                      .map((change) => {
                        return {
                          blockId: change.blockId,
                          date: change.date,
                          locationCode: change.locationCode,
                          loads:
                            change.newValue?.loads === ""
                              ? 0
                              : change.newValue?.loads,
                          stage: change.newValue?.stage,
                          comment: change.newValue?.comment,
                          programCode: change.programCode,
                        }
                      })
                    if (body?.length > 0) {
                      const resp = await executePostSchedule(
                        "POST",
                        "/api/gr/blocks/schedule",
                        body
                      )

                      if (resp) {
                        mergeNewSchedules(resp.newSchedules)
                      }

                      setUnsavedChanges({})
                    }
                  }}
                >
                  SAVE CHANGES
                </Button>
                <Button
                  onClick={() => setUnsavedChanges({})}
                  size="small"
                  color="error"
                >
                  DISCARD
                </Button>
              </>
            )}
          </div>
        )}
        {dataIsLoading || dataIsPosting ? (
          <div className="loadingBar">
            <LinearProgress />
          </div>
        ) : null}
        <ScheduleGrid
          unsavedChanges={unsavedChanges}
          dates={dates}
          setUnsavedChanges={setUnsavedChanges}
          sortedBlockData={sortedBlockData}
          sortDay={sortDay}
          setSortDays={setSortDays}
          onBlockSelect={(blockID) => onBlockSelect(blockID)}
          selectedBlockID={selectedBlock?.id}
          contractSummaries={contractSummaries}
          groupedBlockData={groupedBlockData}
        />

        {selectedBlock && (
          <div className="BlockDetailCont blockDetailContainer ">
            <BlockDetail
              block={selectedBlock}
              close={() => setSelectedBlock(null)}
              reload={() => onBlockSelect(selectedBlock.id)}
              hasWritePermission={hasWritePermission}
              onBlockEdit={() => {}}
              onGeoEdit={() => {}}
            />
          </div>
        )}
      </div>
    </Page>
  )
}
