export const getBaseUrl = () => {
  let url
  switch (process.env.NODE_ENV) {
    case "production":
      url = "https://oneillappapi.azurewebsites.net"
      break
    case "development":
    default:
      url = "http://localhost:3000"
  }

  return url
}
