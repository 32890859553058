import React, { useEffect } from "react"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import { useState } from "react"
import Dialog from "@mui/material/Dialog"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"

const IMAGE_URL_PREFIX = "https://kiptraq.s3.amazonaws.com/ONeillHarvestOps/"

export const sortedTaskTypes = [
  "Bunch Counts",
  "Field Survey",
  "Sugar Testing",
  "Tasting Notes",
  "Fruit Rating",
  "Grower Rating",
]

const RenderImages = ({ taskData }) => {
  const [imageClicked, setImageClicked] = useState(null)

  const images = Object.keys(taskData)
    .filter((key) => key.startsWith("Picture"))
    .map((key) => taskData[key])
    .filter((image) => !!image)

  return (
    <div className="images">
      <>
        {images.map((image) => (
          <img
            onClick={() => {
              setImageClicked(image)
            }}
            key={image}
            src={IMAGE_URL_PREFIX + image}
            alt={image}
          />
        ))}
      </>
      {imageClicked && (
        <Dialog open={true} onClose={() => setImageClicked(null)}>
          <img
            className="modalimage"
            src={IMAGE_URL_PREFIX + imageClicked}
            alt={imageClicked}
          />
        </Dialog>
      )}
    </div>
  )
}

export const renderTaskSnippet = (task) => {
  const data = JSON.parse(task.data)
  const type = task.type

  switch (type) {
    case "Field Survey":
      return (
        <div>
          <div>{data.UserName}</div>
          <div className="comments">{data.Comments}</div>
        </div>
      )
    case "Tasting Notes":
      return (
        <div>
          <div>{data.UserName}</div>
          <div>Released: {data.Release}</div>
          <div>Days to Harvest: {data.DaystoHarvest}</div>
          <div className="comments">{data.Comments}</div>
        </div>
      )
    case "Bunch Counts":
      const countNums = []
      Object.keys(data)
        .filter((key) => key.startsWith("Vine"))
        .map((key) => {
          const count = data[key]
          if (count !== "" && count !== null && count !== undefined) {
            countNums.push(count)
          }
          return 1
        })
      return (
        <div>
          <div>{data.UserName}</div>
          <div>
            Average:{" "}
            {countNums.reduce((acc, count) => acc + count, 0) /
              countNums.length}
          </div>
          <div className="comments">{data.Comments}</div>
        </div>
      )
    case "Sugar Testing":
      const indexes = [1, 2, 3, 4]
      const brixReadings = []
      indexes.forEach((idx) => {
        const location = data["LocationBrix" + idx]
        const reading = data["Brix" + idx]
        if (
          reading !== "" &&
          reading !== null &&
          reading !== undefined &&
          reading !== 0
        ) {
          brixReadings.push({
            location,
            reading,
          })
        }
      })
      return (
        <div>
          <div>{data.UserName}</div>
          <div>
            Field Average Brix:{" "}
            {(
              brixReadings.reduce((acc, reading) => acc + reading.reading, 0) /
              brixReadings.length
            ).toFixed(2)}
          </div>
          {data.LabUser && data.LabUser !== "" ? (
            <div>Lab Completed</div>
          ) : (
            <div>Lab Not Completed</div>
          )}
          <div className="comments">{data.Comments}</div>
        </div>
      )
    case "Fruit Rating":
      return (
        <div>
          <div>{data.UserName}</div>
          <div>
            <b>Overall Rating</b>: {data.OverallRating}
          </div>
          <div className="comments">{data.Comments}</div>
        </div>
      )
    default:
      return null
  }
}

export const renderTask = (task) => {
  const data = JSON.parse(task.data)
  const type = task.type
  const date = new Date(task.date + "T00:00:00").toLocaleDateString()
  const blockName = task.blockName

  switch (type) {
    case "Field Survey":
      return (
        <div>
          <div className="title">
            {data.UserName} - {date} - {blockName}
          </div>
          <div className="comments">{data.Comments}</div>
          <RenderImages taskData={data} />
        </div>
      )
    case "Tasting Notes":
      return (
        <div>
          <div className="title">
            {data.UserName} - {date} - {blockName}
          </div>
          <br />
          <div>Released: {data.Release}</div>
          <div>Days to Harvest: {data.DaystoHarvest}</div>
          <br />
          <div className="comments">{data.Comments}</div>
          <RenderImages taskData={data} />
        </div>
      )
    case "Bunch Counts":
      const countNums = []
      Object.keys(data)
        .filter((key) => key.startsWith("Vine"))
        .map((key) => {
          const count = data[key]
          if (count !== "" && count !== null && count !== undefined) {
            countNums.push(count)
          }
          return 1
        })
      return (
        <div>
          <div className="title">
            {data.UserName} - {date} - {blockName}
          </div>
          <br />
          <div>Location: {data.Location}</div>
          <div>Row: {data.RowNumber}</div>
          <div>Counts: {countNums.join(", ")}</div>
          <div>
            Average:{" "}
            {countNums.reduce((acc, count) => acc + count, 0) /
              countNums.length}
          </div>
          <br />
          <div className="comments">{data.Comments}</div>
          <RenderImages taskData={data} />
        </div>
      )
    case "Sugar Testing":
      const indexes = [1, 2, 3, 4]
      const brixReadings = []
      indexes.forEach((idx) => {
        const location = data["LocationBrix" + idx]
        const reading = data["Brix" + idx]
        if (
          reading !== "" &&
          reading !== null &&
          reading !== undefined &&
          reading !== 0
        ) {
          brixReadings.push({
            location,
            reading,
          })
        }
      })
      return (
        <div>
          <div className="title">
            {data.UserName} - {date} - {blockName}
          </div>
          <br />
          <div>Barcode: {data.LabSampleBarCode}</div>
          <div>Irrigation: {data.Irrigation}</div>
          <div>
            Field Average Brix:{" "}
            {(
              brixReadings.reduce((acc, reading) => acc + reading.reading, 0) /
              brixReadings.length
            ).toFixed(2)}
          </div>
          <br />
          {data.LabUser && data.LabUser !== "" ? (
            <>
              <div>Lab User: {data.LabUser}</div>
              <div>Lab Brix: {data.Brix}</div>
              <div>Lab TA: {data.TA}</div>
              <div>Lab pH: {data.pH}</div>
              <div>Lab VA: {data.VA}</div>
              <div>Lab Fluoride: {data.Fluoride}</div>
            </>
          ) : (
            <div>Lab Not Completed</div>
          )}
          <br />
          <div className="comments">{data.Comments}</div>
          <RenderImages taskData={data} />
        </div>
      )
    case "Fruit Rating":
      const categoryPrefix = {
        BrixLevel: { label: "Brix Level" },
        CaneLiquification: { label: "Cane Liquification" },
        CanopyHealth: { label: "Canopy Health" },
        CroppingLevel: { label: "Cropping Level" },
        FlavorDevelopment: { label: "Flavor Development" },
        FruitColor: { label: "Fruit Color" },
        LightExposure: { label: "Light Exposure" },
        Rot: { label: "Rot" },
        Shrivel: { label: "Shrivel" },
        Sunburn: { label: "Sunburn" },
      }
      return (
        <div>
          <div className="title">
            {data.UserName} - {date} - {blockName}
          </div>
          <br />
          <div>
            <b>Overall Rating</b>: {data.OverallRating}
          </div>
          <br />
          {Object.keys(categoryPrefix).map((prefix) => {
            const rating = data[prefix + "Rating"]
            const comment = data[prefix + "Comment"]
            return (
              <div
                key={prefix}
                style={{
                  paddingBottom: "2px",
                }}
              >
                <div
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  {categoryPrefix[prefix].label}
                </div>
                <div>
                  {rating} {comment ? ` - ${comment}` : ""}
                </div>
              </div>
            )
          })}
          <br />
          <div className="comments">{data.Comments}</div>
          <RenderImages taskData={data} />
        </div>
      )
    default:
      return <div>{JSON.stringify(data)}</div>
  }
}

// will launch modal, it will contain the list of kiptraq blocks linked to the block
// it will allow the user to add or remove links

export const TaskDataViewer = ({
  blockID,
  block,
  hasWritePermission,
  afterSave,
}) => {
  const { execute: executeLoad, data, isLoading } = useFetchWithMsal()
  const { execute: executeLoadAvailable, data: available } = useFetchWithMsal()
  const { execute: executeSendNew } = useFetchWithMsal()
  const [showModal, setShowModal] = useState(null)
  const [selectedAvailable, setSelectedAvailable] = useState(null)
  const [typeTabsValue, setTypeTabsValue] = useState(0)
  const [yearTabsValue, setYearTabsValue] = useState(0)

  const variety = block.externalContract?.varietyCode || block.varietyId

  useEffect(() => {
    executeLoad("GET", "/api/gr/blocks/detail/" + blockID + "/externaltasks")
  }, [executeLoad, blockID])

  useEffect(() => {
    if (variety) {
      executeLoadAvailable(
        "GET",
        "/api/gr/blocks/externalblocks/available?varietyCode=" + variety
      )
    }
  }, [executeLoadAvailable, variety, blockID])

  useEffect(() => {
    setSelectedAvailable(null)
    setYearTabsValue(0)
  }, [blockID])

  if (!data) {
    if (isLoading) return <div>Loading...</div>
    return <div>Error loading data</div>
  }
  const { blockExternalBlocks } = data

  const deleteLink = async (blockID, externalBlockName) => {
    const current = blockExternalBlocks.map((blockLink) => ({
      externalBlockName: blockLink.externalBlockName,
      blockId: blockLink.blockId,
    }))
    const newLinks = current.filter(
      (link) => link.externalBlockName !== externalBlockName
    )
    await executeSendNew("POST", "/api/gr/blocks/externalblocks", {
      blockId: blockID,
      externalBlocks: newLinks.map((link) => ({
        name: link.externalBlockName,
      })),
    })
    executeLoad("GET", "/api/gr/blocks/detail/" + blockID + "/externaltasks")
    afterSave()
  }

  const addLink = async (blockID, externalBlockName) => {
    const current = blockExternalBlocks.map((blockLink) => ({
      externalBlockName: blockLink.externalBlockName,
      blockId: blockLink.blockId,
    }))
    const newLinks = [...current, { externalBlockName, blockId: blockID }]
    await executeSendNew("POST", "/api/gr/blocks/externalblocks", {
      blockId: blockID,
      externalBlocks: newLinks.map((link) => ({
        name: link.externalBlockName,
      })),
    })

    setSelectedAvailable(null)
    executeLoadAvailable(
      "GET",
      "/api/gr/blocks/externalblocks/available?varietyCode=" + variety
    )
    executeLoad("GET", "/api/gr/blocks/detail/" + blockID + "/externaltasks")
    afterSave()
  }

  const allTasks = blockExternalBlocks
    ?.map((blockLink) => blockLink?.externalBlock?.externalBlockTasks.flat())
    .flat()

  const taskTypes = allTasks.reduce((acc, task) => {
    if (!acc[task.type]) acc[task.type] = []
    acc[task.type].push(task)
    return acc
  }, {})

  Object.keys(taskTypes).forEach((key) => {
    taskTypes[key].sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
  })

  return (
    <div className="kt">
      {Object.keys(taskTypes)?.length > 0 && (
        <Box>
          <Tabs
            value={typeTabsValue}
            onChange={(e, newVal) => setTypeTabsValue(newVal)}
            variant="scrollable"
            scrollButtons="auto"
          >
            {sortedTaskTypes.map((type, index) => (
              <Tab
                key={type}
                label={type + " (" + (taskTypes[type]?.length || 0) + ")"}
                value={index}
                id={`data-tab-${index}`}
              />
            ))}
          </Tabs>
        </Box>
      )}
      {Object.keys(taskTypes)?.length > 0 && (
        <div className="dataCont" style={{ display: "flex" }}>
          <div className="tabsCont years">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={yearTabsValue}
              onChange={(e, newVal) => setYearTabsValue(newVal)}
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              {[0, 1, 2, 3].map((yearsAgo, index) => (
                <Tab
                  key={yearsAgo}
                  label={
                    new Date().getFullYear() -
                    yearsAgo +
                    " (" +
                    (taskTypes[sortedTaskTypes[typeTabsValue]]?.filter(
                      (task) => {
                        const taskYear = new Date(task.date).getFullYear()
                        return taskYear === new Date().getFullYear() - yearsAgo
                      }
                    )?.length || 0) +
                    ")"
                  }
                  value={index}
                  id={`data-tab-${index}`}
                />
              ))}
            </Tabs>
          </div>
          {sortedTaskTypes.map(
            (type, index) =>
              index === typeTabsValue && (
                <div
                  className="tabContent years"
                  role="tabpanel"
                  key={type}
                  hidden={index !== typeTabsValue}
                >
                  <div className="tasks">
                    {taskTypes[type]?.filter((task) => {
                      const taskYear = new Date(task.date).getFullYear()
                      return (
                        taskYear === new Date().getFullYear() - yearTabsValue
                      )
                    })?.length > 0 ? (
                      taskTypes[type]
                        ?.filter((task) => {
                          const taskYear = new Date(task.date).getFullYear()
                          return (
                            taskYear ===
                            new Date().getFullYear() - yearTabsValue
                          )
                        })
                        .map((task) => (
                          <div className="task" key={task.id}>
                            {renderTask(task)}
                          </div>
                        ))
                    ) : (
                      <div style={{ padding: "10px" }}>
                        No entries for this year
                      </div>
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      )}
      <div
        style={{
          textAlign: "center",
          margin: "20px 0",
        }}
      >
        <Button
          size="small"
          onClick={() => setShowModal({ blockID })}
          variant="contained"
          color="secondary"
        >
          Manage KipTraq Block Linking
        </Button>
      </div>
      {showModal && (
        <Dialog
          open={true}
          onClose={() => setShowModal(null)}
          fullWidth={true}
          maxWidth="md"
        >
          <div style={{ padding: "20px" }}>
            <h3>KipTraq Block Linking</h3>
            <div>Block: {block.description}</div>
            <div>Variety: {variety}</div>
            <div style={{ margin: "20px 0" }}>
              {blockExternalBlocks.length === 0 && "No KipTraq Blocks linked"}
              {blockExternalBlocks.map((blockLink) => (
                <div key={blockLink.externalBlockName}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div>
                      {`${blockLink.externalBlock.name} - ${
                        blockLink.externalBlock.grower || ""
                      } - ${blockLink.externalBlock.varietyCode}`}
                    </div>
                    <div>
                      <IconButton
                        onClick={() =>
                          deleteLink(blockID, blockLink.externalBlockName)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <div style={{ marginBottom: "5px", fontWeight: "bold" }}>
                Add KipTraq Blocks
              </div>
              <div>
                <Autocomplete
                  options={available || []}
                  getOptionLabel={(option) =>
                    `${option.name} - ${option.grower || ""} - ${
                      option.varietyCode
                    }`
                  }
                  value={selectedAvailable}
                  onChange={(event, newValue) => {
                    setSelectedAvailable(newValue)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  renderInput={(params) => (
                    <TextField
                      placeholder="Search KipTraq Blocks"
                      {...params}
                      variant="outlined"
                    />
                  )}
                />
              </div>
              {selectedAvailable && hasWritePermission && (
                <Button
                  onClick={() => addLink(blockID, selectedAvailable.name)}
                  variant="contained"
                  style={{ marginTop: "10px" }}
                >
                  ADD
                </Button>
              )}
            </div>
          </div>
        </Dialog>
      )}
    </div>
  )
}
