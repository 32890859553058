export const allPermissions = {
  ADMIN: "ADMIN",
  GROWER_RELATIONS_READ: "GROWER_RELATIONS_READ",
  GROWER_RELATIONS_WRITE: "GROWER_RELATIONS_WRITE",
  WINERY_READ: "WINERY_READ",
  WINERY_WRITE: "WINERY_WRITE",
}

export const hasPermission = (usersPermissonObj, permissionChecking) => {
  if (!usersPermissonObj) {
    return false
  }

  if (!!usersPermissonObj[allPermissions.ADMIN]) {
    return true
  }

  if (Array.isArray(permissionChecking)) {
    return permissionChecking.some(
      (permission) => !!usersPermissonObj[permission]
    )
  }

  return !!usersPermissonObj[permissionChecking]
}
