import { useEffect, useState, useContext } from "react"
import { AuthenticatedTemplate } from "@azure/msal-react"
import Page from "../components/Page"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import { renderTask, renderTaskSnippet } from "../components/TaskDataViewer"
import ReadMoreIcon from "@mui/icons-material/ReadMore"
import { BlockDetail } from "../components/BlockDetail"
import { allPermissions, hasPermission } from "../utils/permissions"
import { PermissionsContext } from "../App"
import { ToggleButtonGroup, ToggleButton, LinearProgress } from "@mui/material"
import { sortedTaskTypes } from "../components/TaskDataViewer"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import { useNavigate } from "react-router-dom"
import DateRangePicker from "../components/DateRangePicker"

import "../styles/GRDataLog.css"

const renderEvent = (event, category) => {
  switch (category) {
    case "KipTraq":
      return renderTask(event)
    case "Estimate":
      const latestEstimateTons = event.latestEstimateTons
      const change =
        latestEstimateTons !== undefined &&
        latestEstimateTons !== null &&
        event.tons - latestEstimateTons
      const absChange = change ? Math.abs(change) : null
      return (
        <div>
          <div>{event.email ? event.email.split("@")[0] : ""}</div>
          <div>New Estimate: {event.tons} tons</div>
          <div>
            {latestEstimateTons === undefined || latestEstimateTons === null
              ? "No previous estimate"
              : `Previous Estimate: ${latestEstimateTons} tons`}
          </div>
          <div
            style={{
              backgroundColor:
                change > 0
                  ? "lightgreen"
                  : change < 0
                  ? "lightcoral"
                  : "lightgrey",
            }}
          >
            {change !== undefined &&
              change !== null &&
              `Change: 
          ${
            change > 0
              ? "Up " + absChange + " tons"
              : change < 0
              ? "Down " + absChange + " tons"
              : "No Change"
          }`}
          </div>
          <div className="comments">{event.comment}</div>
        </div>
      )
    default:
      return null
  }
}

const renderEventSnippet = (event, category) => {
  switch (category) {
    case "KipTraq":
      return renderTaskSnippet(event)
    case "Estimate":
      return renderEvent(event, category)
    default:
      return null
  }
}

const DataLogNode = ({ event, onBlockSelect }) => {
  const [expanded, setExpanded] = useState(false)

  const category = event.category || "KipTraq"
  const type = event.type || event.category
  const date = new Date(
    event.date + (event.date.length < 11 ? "T00:00:00" : "")
  ).toLocaleDateString()
  let grower = null
  let contract = null
  let variety = null
  const block =
    category === "KipTraq"
      ? event.externalBlock?.blockExternalBlocks &&
        event.externalBlock.blockExternalBlocks.length > 0
        ? event.externalBlock.blockExternalBlocks[0].block
        : null
      : event.block
  if (block) {
    grower = block.externalContract
      ? block.externalContract.growerName
      : block.grower
    contract =
      block.externalContract &&
      block.externalContract.id.substring(
        0,
        block.externalContract.id.indexOf("*")
      )

    variety = block.externalContract
      ? block.externalContract.varietyCode
      : block.varietyId
  }
  const externalBlockName = category === "KipTraq" ? event.blockName : null

  return (
    <div className="DataLogNode kt">
      <div
        className="DataLogNodeHeader"
        onClick={() => {
          setExpanded(!expanded)
        }}
      >
        <div className="basic">
          <div>
            {date} {type}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "5px",
              color: block ? "blue" : "black",
            }}
            onClick={(e) => {
              if (block) {
                e.stopPropagation()
                onBlockSelect(block.id)
              }
            }}
          >
            <div>
              {block?.description || externalBlockName || ""} {grower}
            </div>
            {block && <ReadMoreIcon fontSize="small" />}
          </div>
          <div>
            {block
              ? variety + (contract ? " " + contract : "")
              : "No Linked Block in Port"}
          </div>
        </div>
        <div className="snippet">
          {!expanded && renderEventSnippet(event, category)}
        </div>
      </div>
      {expanded && (
        <div className="detailCont">{renderEvent(event, category)}</div>
      )}
    </div>
  )
}

export const DataLogPage = () => {
  const { execute, data, isLoading: listLoading } = useFetchWithMsal()
  const { execute: executeGetBlocks } = useFetchWithMsal()
  const [filterState, setFilterState] = useState({
    category: [],
    type: [],
  })

  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 7))
      .toISOString()
      .split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  })

  const navigate = useNavigate()

  const [selectedBlock, setSelectedBlock] = useState(null)

  const [permissions] = useContext(PermissionsContext)
  let hasWritePermission = !!hasPermission(
    permissions,
    allPermissions.GROWER_RELATIONS_WRITE
  )

  const dataURL = `/api/gr/datalog?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`

  useEffect(() => {
    execute("GET", dataURL)
  }, [dataURL, execute])

  const filteredData = data?.filter(
    (event) =>
      (filterState.category.length === 0 ||
        filterState.category.includes(event.category)) &&
      (filterState.type.length === 0 || filterState.type.includes(event.type))
  )

  const sortedData = filteredData?.sort((a, b) => {
    const dateA = new Date(a.date)
    const dateB = new Date(b.date)
    return dateB - dateA
  })

  const onBlockSelect = async (blockID) => {
    if (blockID) {
      if (selectedBlock && selectedBlock.id === blockID) {
        return
      }
      const resp = await executeGetBlocks(
        "GET",
        `/api/gr/blocks?blockID=${blockID}`
      )
      if (resp && resp.length > 0) {
        setSelectedBlock(resp[0])
      }
    } else {
      setSelectedBlock(null)
    }
  }

  return (
    <AuthenticatedTemplate>
      <Page fullHeight>
        <div className="DataLog">
          <div className="ribbon">
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <div>
                <DateRangePicker
                  value={dateRange}
                  setDateRange={setDateRange}
                  disabled={false}
                />
              </div>
              <ToggleButtonGroup
                value={filterState.category}
                onChange={(e, val) =>
                  setFilterState({ ...filterState, category: val })
                }
                size="small"
              >
                <ToggleButton size="small" value="KipTraq">
                  KipTraq
                </ToggleButton>
                <ToggleButton value="Estimate">Estimate</ToggleButton>
              </ToggleButtonGroup>
              <ToggleButtonGroup
                className="xlonly"
                value={filterState.type}
                onChange={(e, val) =>
                  setFilterState({ ...filterState, type: val })
                }
                size="small"
              >
                {sortedTaskTypes.map((type) => (
                  <ToggleButton key={type} value={type}>
                    {type}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => navigate("/gr")}
                endIcon={<OpenInNewIcon />}
              >
                Blocks
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => navigate("/gr/schedule")}
                endIcon={<CalendarMonthIcon />}
              >
                Schedule
              </Button>
            </Stack>
          </div>
          <div className="ListCont">
            {listLoading && (
              <div>
                <LinearProgress />
              </div>
            )}
            {!listLoading && sortedData && sortedData.length === 0 && (
              <div>No Data. Try changing the filters.</div>
            )}
            {sortedData?.map((event) => (
              <div className="DataLogNodeCont" key={event.id}>
                <DataLogNode
                  onBlockSelect={(blockID) => onBlockSelect(blockID)}
                  event={event}
                />
              </div>
            ))}
          </div>
          {selectedBlock && (
            <div className="BlockDetailCont blockDetailContainer ">
              <BlockDetail
                block={selectedBlock}
                close={() => setSelectedBlock(null)}
                reload={() => onBlockSelect(selectedBlock.id)}
                hasWritePermission={hasWritePermission}
                onBlockEdit={() => {}}
                onGeoEdit={() => {}}
              />
            </div>
          )}
        </div>
      </Page>
    </AuthenticatedTemplate>
  )
}
