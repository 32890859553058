import React from "react"

const Page = (props) => {
  return (
    <div
      className={`Page ${props.fullHeight ? "fullHeight" : ""} ${
        props.className ? props.className : ""
      }`}
    >
      {props.children}
    </div>
  )
}

export default Page
