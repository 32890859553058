import { useState, useEffect, useContext, Fragment } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { PermissionsContext } from "../App"
import { allPermissions } from "../utils/permissions"
import { List, ListItemButton, ListItemText, Collapse } from "@mui/material"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react"
import { Button, Dropdown, DropdownButton } from "react-bootstrap"

import { loginRequest } from "../authConfig"
import { AccountPicker } from "./AccountPicker"
import { useLocation } from "react-router-dom"
import { hasPermission } from "../utils/permissions"

export const pageSections = [
  {
    name: "Home",
    location: "/",
    permissions: [],
    pages: [],
  },
  {
    name: "Grower Relations",
    permissions: [
      allPermissions.GROWER_RELATIONS_READ,
      allPermissions.GROWER_RELATIONS_WRITE,
    ],
    pages: [
      {
        name: "Blocks",
        location: "/gr",
        section: "Grower Relations",
        permissions: [
          allPermissions.GROWER_RELATIONS_READ,
          allPermissions.GROWER_RELATIONS_WRITE,
        ],
      },
      {
        name: "Schedule",
        location: "/gr/schedule",
        permissions: [
          allPermissions.GROWER_RELATIONS_READ,
          allPermissions.GROWER_RELATIONS_WRITE,
        ],
      },
      {
        name: "Data Log",
        location: "/gr/data",
        permissions: [
          allPermissions.GROWER_RELATIONS_READ,
          allPermissions.GROWER_RELATIONS_WRITE,
        ],
      },
      {
        name: "Deliveries",
        location: "/gr/deliveries",
        permissions: [
          allPermissions.GROWER_RELATIONS_READ,
          allPermissions.GROWER_RELATIONS_WRITE,
        ],
      },
    ],
  },
  {
    name: "Winery",
    permissions: [allPermissions.WINERY_READ, allPermissions.WINERY_WRITE],
    pages: [
      {
        name: "Tank Farm",
        location: "/tankfarm",
        permissions: [allPermissions.WINERY_READ, allPermissions.WINERY_WRITE],
      },
    ],
  },
]

export const PageLayout = (props) => {
  const [showNavBody, setShowNavBody] = useState(false)
  const [showProfilePicker, setShowProfilePicker] = useState(false)
  const { instance } = useMsal()
  const [permissions] = useContext(PermissionsContext)
  const navigateTo = useNavigate()
  const [openNavs, setOpenNavs] = useState({})

  let activeAccount

  if (instance) {
    activeAccount = instance.getActiveAccount()
  }

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error))
  }

  const location = useLocation()

  useEffect(() => {
    if (location.pathname === "/") {
      setShowNavBody(true)
    } else {
      setShowNavBody(false)
    }
  }, [location.pathname])

  // const handleLoginPopup = () => {
  //   /**
  //    * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
  //    * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
  //    * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
  //    */

  //   instance
  //     .loginPopup({
  //       ...loginRequest,
  //       redirectUri: "/redirect",
  //     })
  //     .catch((error) => console.log(error))
  // }

  const handleLogoutRedirect = () => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    })
  }

  // const handleLogoutPopup = () => {
  //   instance.logoutPopup({
  //     mainWindowRedirectUri: "/", // redirects the top level app after logout
  //     account: instance.getActiveAccount(),
  //   })
  // }

  const handleSwitchAccount = () => {
    setShowProfilePicker(!showProfilePicker)
  }

  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */
  return (
    <div className="appCont">
      <div className={`navToggle ${showNavBody ? "show" : "hide"}`}>
        <div className="title" onClick={() => setShowNavBody(!showNavBody)}>
          <div className="logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 84 84"
            >
              <title>O'Neill Vintners &amp; Distillers</title>{" "}
              <path
                fill="currentColor"
                d="M38.76 40.46H37.7c-.98 0-1.96.05-2.93.16-1.3.14-2.59.47-3.8.98a11.33 11.33 0 00-3.62 2.42l-7.23 7.24-1.05 1.06a9.62 9.62 0 00-3.16 7.26v.75l-.07.26c-.16.22-.35.43-.56.62l-.32.34c-.38.4-.69.75-.95 1.05l-.18.21-1.94 2.11a37.6 37.6 0 01-.32-45.3l1.5 1.53.68.73c.26.3.59.68.99 1.06l.32.34c.2.19.4.4.56.62l.07.25v.82a9.6 9.6 0 003.16 7.25l1.05 1.06 8.36 8.36.05.06a12.27 12.27 0 014.12-1.9l-.38-.37-10.2-10.22a5.41 5.41 0 01-1.9-4.22v-.75a5.6 5.6 0 00-2.08-4.15l-.57-.62-1.82-1.91-4.08-4.22a42 42 0 00.3 57.86l6.87-7.08.16-.17.15-.14a4.75 4.75 0 001.4-3.41v-.75a5.4 5.4 0 011.89-4.22l7.87-7.88.45-.44a7.1 7.1 0 012.13-1.62 11.4 11.4 0 014.22-.78h1.79l.13-4.25zm34.3-26.76l-.11.1-6.55 6.77c-.54.56-1.01 1.18-1.42 1.85-.32.56-.5 1.2-.51 1.84.01.94-.11 1.88-.37 2.78-.3.86-.79 1.65-1.41 2.31-.13.13-9.06 9-9.52 9.5a8.38 8.38 0 01-2.12 1.42c-1.04.4-2.16.58-3.28.54h-.58c-.56 0-1.17-.05-1.8-.1h-.15l-.18 4.22h.07c.85.06 1.73.12 2.63.12h1.12a11.09 11.09 0 007.44-3.28l.42-.44 8.94-8.9a10.6 10.6 0 002.49-4.11c.37-1.24.55-2.53.55-3.82.28-.4.6-.77.93-1.12l1.56-1.7.39-.38 1.05-1.14a36.74 36.74 0 014.23 7.63 37.62 37.62 0 01-4.52 36.45l-.23-.24-1.05-1.06c-.46-.47-.97-1.05-1.76-1.87-.43-.48-.68-.78-.81-.96v-.28c.01-1.29-.17-2.57-.54-3.8a10.46 10.46 0 00-2.48-4.11l-8.72-8.64-.18-.17c-1.02.9-2.2 1.6-3.45 2.11l-.34.13.44.43 9.2 9.14c.62.67 1.1 1.45 1.4 2.32.26.9.39 1.83.37 2.76.01.65.2 1.3.52 1.86.19.34.41.66.66.96l7.4 7.66a41.94 41.94 0 00.27-56.78z"
              ></path>{" "}
              <path
                fill="currentColor"
                d="M64.92 66.95l-.74-.67a5.6 5.6 0 00-4.17-2.1h-.75a5.4 5.4 0 01-4.23-1.9l-8.75-8.73a7.06 7.06 0 01-1.47-2.18 11.18 11.18 0 01-.78-4.13v-.91c0-1.34.08-2.79.11-4.3 0-1.9.3-3.83.32-5.86V35.1c-.07-1.3-.34-2.58-.83-3.79a10.96 10.96 0 00-2.3-3.43l-.24-.24-9-9a10.58 10.58 0 00-4.12-2.48 13.3 13.3 0 00-3.8-.55 9 9 0 01-1.13-.93c-.8-.72-1.32-1.2-1.76-1.62l-1.34-1.27-.09-.09-.2-.17a37.73 37.73 0 0145.17.44l-1.42 1.4-.74.69c-.32.27-.68.59-1.06.98l-.35.34c-.2.2-.4.39-.62.56l-.26.06h-.77a9.63 9.63 0 00-7.26 3.17l-1.06 1.05-7.86 7.84-.19.2a12.32 12.32 0 011.95 4.06l.42-.42 9.7-9.77a5.38 5.38 0 014.23-1.9h.75a5.62 5.62 0 004.16-2.1l6.58-6.47a42.12 42.12 0 00-57.68-.5l.2.23 7.4 7.15c.37.3.76.58 1.16.83.57.32 1.21.5 1.87.51.93-.01 1.87.11 2.77.37.86.3 1.65.78 2.31 1.4.12.12 7.16 7.16 9.04 9.06a6.57 6.57 0 011.5 2.2 8.5 8.5 0 01.53 3.27v.58c0 1.5-.26 3.25-.3 5.19 0 1.41-.12 2.88-.12 4.39 0 .98.04 1.96.15 2.93a13.24 13.24 0 001.34 4.54l.06.14.34.6c.38.58.79 1.13 1.24 1.64l.4.4.4.44 1.44 1.44 6.85 6.84a9.63 9.63 0 007.27 3.17h.77l.25.06c.23.17.44.36.63.56l.35.33c.42.4.78.72 1.05.99.28.27.57.5.72.66l1.3 1.28A37.56 37.56 0 0156.18 77a37.79 37.79 0 01-36.15-4.22l2.21-2.1c.3-.3.66-.62 1.06-1.06.4-.45.78-.68.96-.81l.17-.12c1.3 0 2.58-.18 3.82-.55a10.5 10.5 0 004.11-2.48l9.07-9.06.18-.2a12.51 12.51 0 01-2.17-3.85L39 53l-9.63 9.77a6.45 6.45 0 01-2.32 1.4c-.9.26-1.83.39-2.77.37-.65.01-1.29.2-1.86.52a11 11 0 00-1.8 1.4l-6.92 6.71a42.12 42.12 0 0056.98-.53l-5.18-5.12-.59-.57z"
              ></path>
            </svg>
          </div>
          Port
        </div>
        {hasPermission(permissions, allPermissions.ADMIN) && (
          <div className="AdminLink">
            <NavLink to="/admin">ADMIN</NavLink>
          </div>
        )}
        <div className="auth">
          <AuthenticatedTemplate>
            <DropdownButton
              size="sm"
              variant="secondary"
              drop="bottom"
              title={activeAccount ? activeAccount.name : "Unknown"}
            >
              <Dropdown.Item as="button" onClick={handleSwitchAccount}>
                Switch account
              </Dropdown.Item>
              {/* <Dropdown.Item as="button" onClick={handleLogoutPopup}>
                                Sign out using Popup
                            </Dropdown.Item> */}
              <Dropdown.Item as="button" onClick={handleLogoutRedirect}>
                Sign Out
              </Dropdown.Item>
            </DropdownButton>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Button
              size="sm"
              variant="secondary"
              className="ml-auto"
              title="Sign In"
              onClick={handleLoginRedirect}
            >
              Sign In
            </Button>
          </UnauthenticatedTemplate>
          <AccountPicker
            show={showProfilePicker}
            handleSwitchAccount={handleSwitchAccount}
          />
        </div>
      </div>
      <div className="bottomCont">
        <div
          className={`navBody ${showNavBody ? "show" : "hide"}`}
          style={{
            minWidth: showNavBody ? "200px" : "0px",
            visibility: showNavBody ? "visible" : "hidden",
          }}
        >
          <AuthenticatedTemplate>
            <List>
              {pageSections.map((section) => {
                if (
                  section.permissions.length === 0 ||
                  hasPermission(permissions, section.permissions)
                ) {
                  return (
                    <Fragment key={section.name}>
                      <ListItemButton
                        onClick={() => {
                          if (section.location) {
                            navigateTo(section.location)
                          } else {
                            setOpenNavs({
                              ...openNavs,
                              [section.name]: !!!openNavs[section.name],
                            })
                          }
                        }}
                      >
                        <ListItemText primary={section.name} />
                        {section.pages.length > 0 && (
                          <>
                            {!!openNavs[section.name] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </>
                        )}
                      </ListItemButton>
                      {section.pages.length > 0 && (
                        <Collapse
                          in={!!openNavs[section.name]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {section.pages.map((page) => {
                              if (
                                hasPermission(permissions, page.permissions)
                              ) {
                                return (
                                  <ListItemButton
                                    key={page.name}
                                    onClick={() => navigateTo(page.location)}
                                    sx={{ pl: 4 }}
                                  >
                                    <ListItemText primary={page.name} />
                                  </ListItemButton>
                                )
                              }
                              return null
                            })}
                          </List>
                        </Collapse>
                      )}
                    </Fragment>
                  )
                }
                return null
              })}
            </List>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>You must Sign In!</UnauthenticatedTemplate>
        </div>
        <div className="pageCont">{props.children}</div>
      </div>
    </div>
  )
}
