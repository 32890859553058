import { Button } from "@mui/material"
import { TextField } from "@mui/material"
import LinearProgress from "@mui/material/LinearProgress"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"

import Autocomplete from "@mui/material/Autocomplete"

import "../styles/BlockEditor.css"
import React, { useState } from "react"

import Stack from "@mui/material/Stack"

const currentYear = new Date().getFullYear()
const allYears = Array.from(
  new Array(5),
  (val, index) => currentYear - 1 + index
)

export const BlockEditor = ({
  block,
  prePopulated,
  onCancel,
  onSave,
  error,
  md,
  isLoadingMD,
}) => {
  const defaultBlock = {
    vintage: currentYear,
    externalContractId: null,
    appellationId: "CA",
    varietyId: "CAS",
    repId: null,
    description: null,
    growerName: null,
    deactivate: false,
  }

  const [blockState, setBlockData] = useState(
    !!block
      ? block
      : {
          ...defaultBlock,
          ...prePopulated,
        }
  )

  return (
    <div className="BlockEditor form">
      <div className="header">{blockState?.id ? "Edit" : "New"} Block</div>
      {error && <div className="formRow error">{error}</div>}
      {!md || isLoadingMD ? (
        <div className="formRow">
          <LinearProgress />
        </div>
      ) : (
        <div>
          {blockState?.id && (
            <div className="formRow">Block ID: {blockState?.id || "NEW"}</div>
          )}

          <div className="formRow">
            <TextField
              id="vintselect"
              label="Vintage"
              variant="outlined"
              size="small"
              select
              required
              value={
                blockState?.vintage !== null ? blockState.vintage : currentYear
              }
              SelectProps={{
                native: true,
              }}
              onChange={(e) =>
                setBlockData({
                  ...blockState,
                  vintage: Number(e.target.value),
                  externalContractId: null,
                })
              }
            >
              {allYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </TextField>
          </div>
          <div className="formRow">
            <Autocomplete
              id="extselect"
              value={
                blockState?.externalContractId !== null
                  ? { id: blockState.externalContractId }
                  : null
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, v, r) => {
                setBlockData({
                  ...blockState,
                  externalContractId: v === null ? null : v.id,
                  varietyId: v === null ? blockState.varietyId : v.varietyCode,
                  appellationId:
                    v === null ? blockState.appellationId : v.appellationCode,
                })
              }}
              options={[
                ...md?.contracts
                  .filter((c) => c.vintage === blockState.vintage)
                  .sort((a, b) => a.growerName.localeCompare(b.growerName)),
              ]}
              groupBy={(option) => option.growerName}
              getOptionLabel={(option) => option.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="TSM Contract"
                  variant="outlined"
                  fullWidth
                  SelectProps={{
                    native: true,
                  }}
                  helperText="Leave blank if contract is not yet in TSM. Once it is, you can edit the block to select the correct contract."
                  size="small"
                />
              )}
            />
          </div>
          <div className="formRow">
            <TextField
              label="Description"
              variant="outlined"
              size="small"
              fullWidth
              required
              value={blockState?.description || ""}
              onChange={(e) =>
                setBlockData({
                  ...blockState,
                  description: e.target.value === "" ? null : e.target.value,
                })
              }
            />
          </div>
          <div className="formRow">
            <TextField
              id="repselect"
              label="Primary Rep"
              variant="outlined"
              size="small"
              required
              select
              value={blockState?.repId !== null ? blockState.repId : "x"}
              SelectProps={{
                native: true,
              }}
              onChange={(e) =>
                setBlockData({
                  ...blockState,
                  repId: e.target.value === "" ? null : e.target.value,
                })
              }
            >
              <option key={"repx"} value={""}></option>
              {md.reps.map((rep) => (
                <option key={rep.id} value={rep.id}>
                  {rep.name}
                </option>
              ))}
            </TextField>
          </div>
          <div className="formRow">
            <TextField
              id="csselect"
              label="Contract Status"
              variant="outlined"
              size="small"
              required
              select
              fullWidth
              value={
                blockState?.contractstatusId !== null
                  ? blockState.contractstatusId
                  : "x"
              }
              SelectProps={{
                native: true,
              }}
              onChange={(e) =>
                setBlockData({
                  ...blockState,
                  contractstatusId:
                    e.target.value === "" ? null : e.target.value,
                })
              }
            >
              <option key={"csx"} value={""}></option>
              {md.contractStatuses.map((rep) => (
                <option key={rep.id} value={rep.id}>
                  {rep.name}
                </option>
              ))}
            </TextField>
          </div>
          <div className="formRow">
            <TextField
              id="csselect"
              label="Delivery Status"
              variant="outlined"
              size="small"
              required
              select
              fullWidth
              value={
                blockState?.deliverystatusId !== null
                  ? blockState.deliverystatusId
                  : "x"
              }
              SelectProps={{
                native: true,
              }}
              onChange={(e) =>
                setBlockData({
                  ...blockState,
                  deliverystatusId:
                    e.target.value === "" ? null : e.target.value,
                })
              }
            >
              <option key={"dsx"} value={""}></option>
              {md.deliveryStatuses.map((rep) => (
                <option key={rep.id} value={rep.id}>
                  {rep.name}
                </option>
              ))}
            </TextField>
          </div>
          {blockState.externalContractId === null ? (
            <>
              <div className="formRow">
                <TextField
                  id="growerselect"
                  label="Grower"
                  variant="outlined"
                  size="small"
                  select
                  value={
                    blockState?.growerName !== null
                      ? blockState.growerName
                      : "x"
                  }
                  SelectProps={{
                    native: true,
                  }}
                  onChange={(e) =>
                    setBlockData({
                      ...blockState,
                      growerName: e.target.value === "" ? null : e.target.value,
                    })
                  }
                >
                  <option key={"x"} value={""}></option>
                  {[...new Set(md.contracts.map((c) => c.growerName))]
                    .sort((a, b) => a.localeCompare(b))
                    .map((growerName) => (
                      <option key={growerName} value={growerName}>
                        {growerName}
                      </option>
                    ))}
                </TextField>
              </div>
              <div className="formRow">
                <TextField
                  id="varselect"
                  label="Variety"
                  variant="outlined"
                  size="small"
                  required
                  select
                  value={
                    blockState?.varietyId !== null ? blockState.varietyId : "x"
                  }
                  SelectProps={{
                    native: true,
                  }}
                  onChange={(e) =>
                    setBlockData({
                      ...blockState,
                      varietyId: e.target.value,
                    })
                  }
                >
                  {md.varieties.map((variety) => (
                    <option key={variety.id} value={variety.id}>
                      {variety.description}
                    </option>
                  ))}
                </TextField>
              </div>
              <div className="formRow">
                <TextField
                  id="appselect"
                  label="Appellation"
                  variant="outlined"
                  size="small"
                  required
                  select
                  value={
                    blockState?.appellationId !== null
                      ? blockState.appellationId
                      : "x"
                  }
                  SelectProps={{
                    native: true,
                  }}
                  onChange={(e) =>
                    setBlockData({
                      ...blockState,
                      appellationId: e.target.value,
                    })
                  }
                >
                  {md.appellations.map((app) => (
                    <option key={app.id} value={app.id}>
                      {app.description}
                    </option>
                  ))}
                </TextField>
              </div>
              <div className="formRow">
                <TextField
                  label="Contract Tons"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  required
                  value={blockState?.contractTons || ""}
                  onChange={(e) =>
                    setBlockData({
                      ...blockState,
                      contractTons:
                        e.target.value === "" ? null : e.target.value,
                    })
                  }
                />
              </div>
            </>
          ) : (
            <div className="formRow">
              TSM info will be used for other fields
            </div>
          )}
          <div className="formRow">
            <FormGroup>
              <FormControlLabel
                size="small"
                control={
                  <Switch
                    value={!!blockState.deactivate}
                    onClick={() =>
                      setBlockData({
                        ...blockState,
                        deactivate: !!!blockState.deactivate,
                      })
                    }
                  />
                }
                label="Deactivate? (Currently will not be able to re-activate)"
              />
            </FormGroup>
          </div>
        </div>
      )}
      <Stack style={{ margin: "20px 0 10px 0" }} direction="row" spacing={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSave(blockState)}
        >
          SAVE
        </Button>
        <Button variant="outline" color="secondary" onClick={onCancel}>
          CANCEL
        </Button>
      </Stack>
    </div>
  )
}
