// this react component will display a chart using @mui/x-charts
// the chart will display the estimates for the block
// the x axis will be the date and the y axis will be the tons

import React from "react"
import { LineChart } from "@mui/x-charts/LineChart"

function getAllDaysOfYear(year) {
  let date = new Date(year, 0, 1) // January 1 of the given year
  let days = []

  while (date.getFullYear() === year) {
    days.push(new Date(date)) // Add a copy of the date to the array
    date.setDate(date.getDate() + 1) // Increment the day by one
  }

  return days
}

export const EstimatesChart = ({ block }) => {
  const contractTons = block.externalContract
    ? block.externalContract.contractTons
    : block.contractTons

  const vintage = block.externalContract
    ? block.externalContract.vintage
    : block.vintage

  const contractTonsFakeEstimate = React.useMemo(() => {
    return {
      date: new Date(vintage, 0, 1),
      tons: contractTons,
    }
  }, [contractTons, vintage])

  const data = block?.blockEstimates.map((estimate) => ({
    date: new Date(estimate.createdAt),
    tons: estimate.tons,
  }))

  const augmentedData = React.useMemo(() => {
    const today = new Date()
    const todayEstimate = {
      date: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      tons:
        data && data.length > 0 ? data[0]?.tons : contractTonsFakeEstimate.tons,
    }
    return [contractTonsFakeEstimate, todayEstimate, ...data]
  }, [data, contractTonsFakeEstimate])

  const daysInVintage = React.useMemo(
    () => getAllDaysOfYear(vintage),
    [vintage]
  )

  const daysWithEstimates = React.useMemo(() => {
    return daysInVintage.map((day) => {
      const estimate = augmentedData?.find((e) => {
        const eDate = new Date(e.date)
        const dDate = new Date(day)
        return eDate.setHours(0, 0, 0, 0) === dDate.setHours(0, 0, 0, 0)
      })
      return estimate ? estimate.tons : null
    })
  }, [augmentedData, daysInVintage])

  if (!block || !block.blockEstimates) {
    return null
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "0 10px",
        width: "100%",
      }}
    >
      <LineChart
        height={300}
        xAxis={[
          {
            data: daysInVintage,
            scaleType: "time",
            valueFormatter: (date) => date.toLocaleDateString(),
          },
        ]}
        series={[
          {
            curve: "stepAfter",
            data: daysWithEstimates,
            connectNulls: true,
          },
        ]}
      />
    </div>
  )
}
