import { useEffect, useState } from "react"
import { AuthenticatedTemplate } from "@azure/msal-react"
import Page from "../components/Page"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import { protectedResources } from "../authConfig"

import "../styles/TankFarm.css"

export const TankFarm = () => {
  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.apiTodoList.scopes.read,
  })

  const [tankListData, setTankListData] = useState(null)
  const [selectedSection, setSelectedSection] = useState(null)

  useEffect(() => {
    if (!tankListData) {
      execute("GET", "/api/tanks").then((response) => {
        if (response && response.length > 0) {
          setTankListData(response)
          setSelectedSection(response[0]["LocationSortOrder"])
        }
      })
    }
  }, [execute, tankListData])

  if (error) {
    return <div>Error: {error.message}</div>
  }

  if (!tankListData) {
    return <div>Loading...</div>
  }

  if (tankListData.length === 0) {
    return <div>No data found</div>
  }

  const uniqueLocationSortOrder = [
    ...new Set(
      tankListData && tankListData.length > 0
        ? tankListData?.map((t) => t.LocationSortOrder)
        : []
    ),
  ]

  return (
    <AuthenticatedTemplate>
      <Page fullHeight>
        <div className="sectionCont">
          {uniqueLocationSortOrder.map((locationSortOrder) => (
            <div
              className={`${
                locationSortOrder === selectedSection
                  ? "selected"
                  : "unselected"
              } `}
              key={locationSortOrder}
              onClick={() => setSelectedSection(locationSortOrder)}
            >
              {locationSortOrder}
            </div>
          ))}
        </div>
        {tankListData.length > 0 && (
          <div className="farmCont">
            <svg>
              {tankListData
                .filter((t) => t.LocationSortOrder === selectedSection)
                .map((tank) => (
                  <g key={tank.TankID}>
                    <circle
                      className="tank"
                      cx={Number(tank.TankFarmPosition.split(",")[1]) * 45}
                      cy={Number(tank.TankFarmPosition.split(",")[0]) * 45}
                      r={20}
                      fill={"blue"}
                    ></circle>
                    <text
                      x={Number(tank.TankFarmPosition.split(",")[1]) * 45}
                      y={Number(tank.TankFarmPosition.split(",")[0]) * 45}
                      textAnchor="middle"
                      dy=".3em"
                      fontSize={12}
                      fill="white"
                    >
                      {tank.TankID}
                    </text>
                  </g>
                ))}
            </svg>
          </div>
        )}
      </Page>
    </AuthenticatedTemplate>
  )
}
