import React, { useState, useEffect, useContext } from "react"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import { DataGrid, GridToolbarQuickFilter, gridClasses } from "@mui/x-data-grid"
import LinearProgress from "@mui/material/LinearProgress"
import Stack from "@mui/material/Stack"
import RefreshIcon from "@mui/icons-material/Refresh"
import IconButton from "@mui/material/IconButton"
import MapIcon from "@mui/icons-material/Map"
import MapOutlinedIcon from "@mui/icons-material/MapOutlined"
import { BlockEditor } from "../components/BlockEditor"
import { GeoEditor } from "../components/GeoEditor"
import Page from "../components/Page"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import "../styles/GR.css"
import { Map } from "../components/Map"
import { ProgramEditor } from "../components/ProgramEditor"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import { Dialog } from "@mui/material"
import { Box } from "@mui/material"
import { useSearchParams, useNavigate, useLocation } from "react-router-dom"
import { EstimateEditor } from "../components/EstimateEditor"
import MyLocationIcon from "@mui/icons-material/MyLocation"
import NearMeIcon from "@mui/icons-material/NearMe"
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined"
import Tooltip from "@mui/material/Tooltip"
import {
  FilterController,
  blockDataToFilterFields,
} from "../components/FilterController"
import { allPermissions, hasPermission } from "../utils/permissions"
import { PermissionsContext } from "../App"
import { Button } from "@mui/material"
import MissingBlocks from "../components/MissingBlocks"
import { BlockDetail } from "../components/BlockDetail"
import InsightsIcon from "@mui/icons-material/Insights"
const MAP_VIEW_QUERY_PARAM = "mapView"

export const GRHome = () => {
  const { state: navState } = useLocation()
  const navFilterState = navState?.filter || {}

  const [permissions] = useContext(PermissionsContext)

  const {
    execute: executeGetBlocks,
    data: blockData,
    isLoading,
  } = useFetchWithMsal()
  const { execute: executeSaveBlock, isLoading: isSaving } = useFetchWithMsal()
  const { execute: executeSaveBlockPrograms } = useFetchWithMsal()
  const { execute: executeSaveBlockEstimates } = useFetchWithMsal()
  const {
    execute: executeLoadMD,
    isLoading: isLoadingMD,
    data: md,
  } = useFetchWithMsal()

  const [location, setLocation] = useState({ centerCount: 0, centerMap: false })
  const [sortByLocation, setSortByLocation] = useState(false)
  const [filterState, setFilterState] = useState(navFilterState)

  const [blockEditor, setBlockEdit] = useState(null)
  const [geoEditor, setGeoEdit] = useState(null)
  const [blockEstimateEditor, setBlockEstimateEdit] = useState(null)
  const [filteredBlockId, setFilteredBlockId] = useState(null)
  const [programEditor, setProgramEdit] = useState(null)
  const [saveBlockError, setSaveBlockError] = useState(null)
  const [missingBlocks, setMissingBlocks] = useState(null)

  const [sortState, setSortState] = useState([{ field: "id", sort: "desc" }])

  const navigate = useNavigate()

  let [searchParams, setSearchParams] = useSearchParams()
  const mapView = !!searchParams.get(MAP_VIEW_QUERY_PARAM)

  const locationURLParam =
    sortByLocation && location ? `lat=${location.lat}&lng=${location.lng}` : ""
  const blocksURL = `/api/gr/blocks?${locationURLParam}`

  useEffect(() => {
    if (!blockData) {
      executeGetBlocks("GET", blocksURL)
    }
  }, [executeGetBlocks, blockData, blocksURL])

  useEffect(() => {
    if (!md) {
      executeLoadMD("GET", "/api/gr/md")
    }
  }, [executeLoadMD, md])

  const saveBlock = async (block, validate) => {
    if (validate) {
      if (!block.description) {
        setSaveBlockError("Description is required")
        return
      }
    }
    await executeSaveBlock("POST", "/api/gr/blocks", block)
    setBlockEdit(null)
    setGeoEdit(null)
    setSaveBlockError(null)
    executeGetBlocks("GET", blocksURL)
  }

  const saveBlockPrograms = async (id, newPrograms) => {
    await executeSaveBlockPrograms("POST", "/api/gr/blocks/programs", {
      blockId: id,
      programs: newPrograms,
    })
    setProgramEdit(null)
    executeGetBlocks("GET", blocksURL)
  }

  const saveBlockEstimates = async (id, newEstimate) => {
    await executeSaveBlockEstimates("POST", "/api/gr/blocks/estimates", {
      blockId: id,
      estimate: newEstimate,
    })
    setBlockEstimateEdit(null)
    executeGetBlocks("GET", blocksURL)
  }

  const filteredBlock =
    filteredBlockId &&
    blockData?.find((b) => b.id === parseInt(filteredBlockId))

  const handleToggleMapView = () => {
    const newMapView = !mapView
    let queryParams = { mapView: 1 }
    if (!newMapView) {
      delete queryParams[MAP_VIEW_QUERY_PARAM]
    }
    setSearchParams(`?${new URLSearchParams(queryParams)}`)
  }

  useEffect(
    () => {
      if (sortByLocation) {
        refreshCurrentLocation(false)
        setSortState([])
        return
      }

      if (!sortByLocation) {
        setSortState([{ field: "id", sort: "desc" }])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortByLocation]
  )

  const refreshCurrentLocation = (center) => {
    console.log("getting location")

    navigator.geolocation.getCurrentPosition((position) => {
      const latLng = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      setLocation({
        ...latLng,
        centerCount: !!center ? location.center + 1 : location.center,
        centerMap: !!center,
      })

      if (sortByLocation) {
        const blocksURL = `/api/gr/blocks?lat=${latLng.lat}&lng=${latLng.lng}`
        executeGetBlocks("GET", blocksURL)
      }
    })
  }

  const filterFields = blockDataToFilterFields(blockData)

  let hasWritePermission = !!hasPermission(
    permissions,
    allPermissions.GROWER_RELATIONS_WRITE
  )

  const filteredBlockData =
    filterFields === null
      ? blockData
      : blockData?.filter((block) => {
          let show = true
          Object.entries(filterState).forEach(([fieldKey, value]) => {
            if (!value || value.length === 0) {
              return
            }

            const filterField = filterFields.find(
              (f) => f.fieldKey === fieldKey
            )
            const fieldValue = filterField.getValueKey(block)

            if (filterField.multi) {
              if (
                !value.map((x) => x.key).some((x) => fieldValue.includes(x))
              ) {
                show = false
                return
              }
            } else {
              if (!value.map((x) => x.key).includes(fieldValue)) {
                show = false
                return
              }
            }
          })
          return show
        })

  return (
    <Page fullHeight>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
        }}
      >
        <div className="GRribbon ribbon">
          <Stack direction="row" spacing={1} alignItems={"center"}>
            <IconButton
              size="small"
              variant="contained"
              color="primary"
              onClick={() => executeGetBlocks("GET", blocksURL)}
            >
              <RefreshIcon />
            </IconButton>
            <IconButton
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setBlockEdit({ type: "new" })}
              disabled={!hasWritePermission}
            >
              <AddCircleIcon />
            </IconButton>
            <Tooltip title="Toggle Map View">
              <IconButton size="small" onClick={handleToggleMapView}>
                {mapView ? <MapIcon color="primary" /> : <MapOutlinedIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Sort list by current proximity">
              <IconButton
                size="small"
                onClick={() => setSortByLocation(!sortByLocation)}
              >
                {sortByLocation ? <NearMeIcon /> : <NearMeOutlinedIcon />}
              </IconButton>
            </Tooltip>
            {mapView && (
              <Tooltip title="Center map to current location">
                <IconButton
                  size="small"
                  onClick={() => refreshCurrentLocation(true)}
                >
                  <MyLocationIcon />
                </IconButton>
              </Tooltip>
            )}
            <div>
              <FilterController
                fields={filterFields}
                values={filterState}
                onChange={(fieldKey, newState) =>
                  setFilterState({
                    ...filterState,
                    [fieldKey]: newState,
                  })
                }
                clearAll={() => setFilterState({})}
              />
            </div>
            {filteredBlockData && (
              <Stack
                className="totalscont"
                direction="row"
                spacing={1}
                alignItems={"center"}
              >
                <div className="count">
                  {filteredBlockData?.length.toLocaleString()} of{" "}
                  {blockData?.length.toLocaleString()} Blocks;
                </div>
                <div className="tons">
                  {filteredBlockData
                    ?.reduce(
                      (acc, b) =>
                        acc +
                        (b.blockEstimates?.length > 0
                          ? b.blockEstimates[0].tons
                          : b.externalContract?.contractTons ||
                            b.contractTons ||
                            0),
                      0
                    )
                    .toLocaleString()}
                  {" Tons;"}
                </div>
                <div className="acres">
                  {Number(
                    filteredBlockData
                      ?.reduce((acc, b) => acc + (b.calculated_acres || 0), 0)
                      .toFixed(2)
                  ).toLocaleString()}
                  {" Acres"}
                </div>
              </Stack>
            )}
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button
              className="hidemobile"
              size="small"
              onClick={() => setMissingBlocks(true)}
            >
              Missing Blocks
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => navigate("/gr/data")}
              endIcon={<InsightsIcon />}
            >
              Data Log
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() =>
                navigate("/gr/schedule", { state: { filter: filterState } })
              }
              endIcon={<CalendarMonthIcon />}
            >
              Schedule
            </Button>
          </Stack>
        </div>
        <div
          className={
            (filteredBlock
              ? "mapAndGridAndDetailContainer filteredBlock"
              : "mapAndGridAndDetailContainer") + (mapView ? " mapView" : "")
          }
        >
          <div
            className={
              filteredBlock
                ? "mapAndGridContainer filteredBlock"
                : "mapAndGridContainer"
            }
          >
            {mapView ? (
              <div style={{ flex: 1 }}>
                <Map
                  selectedBlockID={filteredBlock ? filteredBlock.id : null}
                  blocks={filteredBlockData}
                  defaultMapType={"roadmap"}
                  onBlockClick={(blockId) => setFilteredBlockId(blockId)}
                  location={location}
                />
              </div>
            ) : null}
            <div
              style={{
                width: "100%",
                height: "100%",
                flex: 1,
                maxHeight: mapView ? "300px" : "100%",
              }}
              className="gridContainer"
            >
              <DataGrid
                slots={{
                  loadingOverlay: LinearProgress,
                  toolbar: GridToolbarQuickFilter,
                }}
                onRowSelectionModelChange={(e) => {
                  if (e.length === 0) {
                    setFilteredBlockId(null)
                    return
                  } else {
                    setFilteredBlockId(e.length > 0 ? e[0] : null)
                  }
                }}
                rowSelectionModel={filteredBlock ? [filteredBlock.id] : []}
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 40}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "id", sort: "asc" }],
                  },
                }}
                sortingMode={sortByLocation && location ? "server" : "client"}
                sortModel={sortState}
                onSortModelChange={(e) => setSortState(e)}
                loading={isLoading || isSaving}
                rows={filteredBlockData ? filteredBlockData : []}
                hideFooterSelectedRowCount={true}
                disableDensitySelector
                pagination
                columnHeaderHeight={40}
                pageSizeOptions={[100]}
                columns={[
                  {
                    field: "id",
                    headerName: "ID",
                    width: 60,
                  },
                  {
                    field: "description",
                    headerName: "Description",
                    flex: 1,
                    minWidth: 160,
                    cellClassName: "gridCell overFlow",
                  },
                  {
                    field: "variety",
                    headerName: "Variety",
                    flex: 1,
                    minWidth: 150,
                    cellClassName: "gridCell overFlow",
                    valueGetter: ({ row }) =>
                      row.externalContract
                        ? row.externalContract.variety?.description
                        : row.variety?.description,
                  },
                  {
                    field: "appellationId",
                    headerName: "App",
                    width: 40,
                    valueGetter: ({ row }) =>
                      row.externalContract
                        ? row.externalContract.appellation?.id
                        : row.appellation?.id,
                  },
                  {
                    field: "growerName",
                    headerName: "Grower",
                    minWidth: 200,
                    cellClassName: "gridCell overFlow",
                    flex: 1,
                    valueGetter: ({ row }) =>
                      row.externalContract
                        ? row.externalContract.growerName
                        : row.growerName,
                  },
                  {
                    field: "vintage",
                    headerName: "Vintage",
                    width: 80,
                  },
                  {
                    field: "program",
                    headerName: "Programs",
                    cellClassName: "gridCell allWidth",
                    minWidth: 180,
                    renderCell: ({ row }) => (
                      <div
                        style={{
                          cursor: hasWritePermission ? "pointer" : "default",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                          backgroundColor: "#f1f1f1",
                          padding: "5px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          if (!hasWritePermission) {
                            return
                          }

                          setProgramEdit({ block: row })
                        }}
                      >
                        {row.blockPrograms?.length > 0
                          ? `${
                              row.blockPrograms.filter((p) => p.isDefault)[0]
                                ?.programCode
                            } ${
                              row.blockPrograms.length > 1
                                ? "(" + row.blockPrograms.length + ")"
                                : ""
                            }`
                          : "None"}
                      </div>
                    ),
                  },
                  {
                    field: "contractTons",
                    headerName: "Contract Tons",
                    minWidth: 100,
                    valueGetter: ({ row }) =>
                      row.externalContract
                        ? row.externalContract.contractTons
                        : row.contractTons,
                  },
                  {
                    field: "estimates",
                    headerName: "Est. Tons",
                    cellClassName: "gridCell allWidth",
                    minWidth: 120,
                    valueGetter: ({ row }) =>
                      row.blockEstimates?.length > 0
                        ? row.blockEstimates[0].tons // asumes sorted
                        : null,
                    renderCell: ({ row }) => (
                      <div
                        style={{
                          cursor: hasWritePermission ? "pointer" : "default",
                          height: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "relative",
                          backgroundColor:
                            row.blockEstimates?.length === 0
                              ? "red"
                              : "#f1f1f1",
                          padding: "5px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation()

                          if (!hasWritePermission) {
                            return
                          }
                          setBlockEstimateEdit({ block: row })
                        }}
                      >
                        {row.blockEstimates?.length > 0 &&
                          row.blockEstimates[0].comment && (
                            <div
                              style={{
                                width: "5px",
                                height: "5px",
                                position: "absolute",
                                right: "0",
                                top: "0",
                                backgroundColor: "#aa1e2e",
                              }}
                            ></div>
                          )}

                        {row.blockEstimates?.length > 0
                          ? row.blockEstimates[0].tons // asumes sorted
                          : "None"}

                        {row.blockEstimates?.length > 1 && (
                          <div
                            style={{
                              color:
                                row.blockEstimates[0].tons -
                                  row.blockEstimates[1].tons >
                                0
                                  ? "green"
                                  : row.blockEstimates[0].tons -
                                      row.blockEstimates[1].tons <
                                    0
                                  ? "red"
                                  : "black",
                            }}
                          >
                            {row.blockEstimates[0].tons -
                              row.blockEstimates[1].tons >
                            0 ? (
                              <>&#11205;</>
                            ) : row.blockEstimates[0].tons -
                                row.blockEstimates[1].tons <
                              0 ? (
                              <>&#11206;</>
                            ) : (
                              <>
                                <>&#11208;</>
                              </>
                            )}
                            {row.blockEstimates[1].tons > 0 &&
                              (
                                ((row.blockEstimates[0].tons -
                                  row.blockEstimates[1].tons) /
                                  row.blockEstimates[1].tons) *
                                100
                              ).toFixed(0) + "%"}
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    field: "estimate_date",
                    headerName: "Latest Est.",
                    cellClassName: "gridCell allWidth",
                    minWidth: 120,
                    valueGetter: ({ row }) =>
                      row.blockEstimates?.length > 0
                        ? new Date(row.blockEstimates[0].createdAt)
                        : null,
                    valueFormatter: ({ value }) =>
                      value ? value.toLocaleDateString() : "",
                  },
                  {
                    field: "repId",
                    headerName: "Rep",
                    flex: 0.6,
                    minWidth: 80,
                    cellClassName: "gridCell overFlow",
                    valueGetter: ({ row }) => row.rep?.name,
                  },
                  {
                    field: "contractstatusId",
                    headerName: "Contract",
                    flex: 0.6,
                    minWidth: 100,
                    cellClassName: "gridCell overFlow",
                    valueGetter: ({ row }) => row.contractstatus?.name,
                    renderCell: ({ row }) => (
                      <div
                        className="gridCell allWidth"
                        style={{
                          backgroundColor:
                            row.contractstatus?.name !== "Signed"
                              ? "green"
                              : "transparent",
                          padding: "5px",
                        }}
                      >
                        {row.contractstatus?.name}
                      </div>
                    ),
                  },
                  {
                    field: "externalContractId",
                    headerName: "TSM",
                    width: 80,
                    valueGetter: ({ row }) =>
                      row.externalContract
                        ? row.externalContract.id.substring(
                            0,
                            row.externalContract.id.indexOf("*")
                          )
                        : "No Link",
                    renderCell: ({ row }) =>
                      row.externalContract ? (
                        <div className="gridCell allWidth allHeight">
                          {row.externalContract.id.substring(
                            0,
                            row.externalContract.id.indexOf("*")
                          )}
                        </div>
                      ) : (
                        <div className="gridCell allWidth allHeight error">
                          No Link
                        </div>
                      ),
                  },
                  {
                    field: "externalBlocks",
                    headerName: "KipTraq",
                    width: 80,
                    valueGetter: ({ row }) =>
                      row.blockExternalBlocks
                        ? row.blockExternalBlocks.length
                        : 0,
                    renderCell: ({ row }) =>
                      row.blockExternalBlocks?.length ? (
                        <div className="gridCell allWidth allHeight">
                          {row.blockExternalBlocks.length}
                        </div>
                      ) : (
                        <div className="gridCell allWidth allHeight error">
                          No Link
                        </div>
                      ),
                  },
                  {
                    field: "calculated_acres",
                    headerName: "Acres",
                    minWidth: 100,
                    valueGetter: ({ row }) =>
                      row.calculated_acres ? row.calculated_acres : null,
                    valueFormatter: ({ value }) =>
                      value ? value.toFixed(2) : "",
                  },
                  {
                    field: "calculated_tons_per_acre",
                    headerName: "Tons/Acre",
                    minWidth: 100,
                    valueGetter: ({ row }) =>
                      row.calculated_acres &&
                      row.blockEstimates?.length > 0 &&
                      row.blockEstimates[0].tons !== null &&
                      !isNaN(row.blockEstimates[0].tons)
                        ? row.blockEstimates[0].tons / row.calculated_acres
                        : null,
                    valueFormatter: ({ value }) =>
                      value ? value.toFixed(2) : "",
                  },
                ]}
                sx={{
                  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                    {
                      outline: "none",
                    },
                  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                    {
                      outline: "none",
                    },
                }}
              />
            </div>
          </div>
          <div
            className={
              filteredBlock
                ? "blockDetailContainer shown"
                : "blockDetailContainer"
            }
          >
            {filteredBlock && (
              <BlockDetail
                parentSupportedFeatures={true}
                block={filteredBlock}
                close={() => setFilteredBlockId(null)}
                reload={() => executeGetBlocks("GET", blocksURL)}
                hasWritePermission={hasWritePermission}
                onBlockEdit={(x) => setBlockEdit(x)}
                onGeoEdit={(x) => setGeoEdit(x)}
              />
            )}
          </div>
        </div>

        <Dialog
          fullWidth
          open={!!blockEditor}
          onClose={() => setBlockEdit(null)}
        >
          <Box>
            <BlockEditor
              onCancel={() => setBlockEdit(null)}
              block={blockEditor?.block}
              error={saveBlockError}
              onSave={(b) => {
                saveBlock(b, true)
              }}
              md={md}
              isLoadingMD={isLoadingMD}
              prePopulated={blockEditor?.prePopulated || {}}
            />
          </Box>
        </Dialog>
        <Dialog open={!!geoEditor} onClose={() => setGeoEdit(null)}>
          <Box>
            <GeoEditor
              onCancel={() => setGeoEdit(null)}
              block={geoEditor?.block}
              onSave={(b) => saveBlock(b, false)}
            />
          </Box>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={!!programEditor}
          onClose={() => setProgramEdit(null)}
        >
          <Box>
            <ProgramEditor
              readOnly={!hasWritePermission}
              md={md}
              onCancel={() => setProgramEdit(null)}
              block={programEditor?.block}
              onSave={(id, p) => saveBlockPrograms(id, p)}
            />
          </Box>
        </Dialog>
        <Dialog
          open={!!blockEstimateEditor}
          onClose={() => setBlockEstimateEdit(null)}
        >
          <Box>
            <EstimateEditor
              onCancel={() => setBlockEstimateEdit(null)}
              block={blockEstimateEditor?.block}
              onSave={(id, p) => saveBlockEstimates(id, p)}
            />
          </Box>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={!!missingBlocks}
          onClose={() => setMissingBlocks(null)}
        >
          <Box>
            <MissingBlocks
              onBeginCreate={(contractId) => {
                setMissingBlocks(null)
                setBlockEdit({
                  type: "new",
                  prePopulated: { externalContractId: contractId },
                })
              }}
            />
          </Box>
        </Dialog>
      </div>
    </Page>
  )
}
