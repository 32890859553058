import React, { useState } from "react"
import { Button } from "@mui/material"
import Stack from "@mui/material/Stack"
import { OutlinedInput, InputAdornment, TextField } from "@mui/material"

// this component will be used to add estimates to a block
// an estimate consists of a tons number, and a comment
export const EstimateEditor = ({ block, onCancel, onSave }) => {
  const [estimate, setEstimate] = useState({ tons: null, comment: "" })
  const [error, setError] = useState(null)

  const save = () => {
    if (estimate.tons < 0 || estimate.tons > 1000000) {
      setError("Invalid estimate")

      return
    }

    onSave(block.id, estimate)
  }

  if (!block) {
    return null
  }

  return (
    <div className="EstimateEditor" style={{ padding: "10px" }}>
      <div className="header">NEW ESTIMATE</div>
      <div>{block.description}</div>
      {block.calculated_acres && (
        <div>{block.calculated_acres?.toFixed(2) || ""} acres</div>
      )}
      {error && <div style={{ color: "red", fontWeight: "bold" }}>{error}</div>}
      <div>
        <div className="formRow">
          <OutlinedInput
            value={
              estimate.tons === null ||
              estimate.tons === undefined ||
              isNaN(estimate.tons)
                ? ""
                : estimate.tons
            }
            type="number"
            onChange={(e) =>
              setEstimate({ ...estimate, tons: parseFloat(e.target.value) })
            }
            fullWidth
            id="tonsestimate"
            endAdornment={<InputAdornment position="end">tons</InputAdornment>}
          />
        </div>
        {block.calculated_acres &&
          estimate.tons !== null &&
          !isNaN(estimate.tons) && (
            <div>
              {(estimate.tons / block.calculated_acres).toFixed(2)} tons/acres
            </div>
          )}
        <div className="formRow">
          <TextField
            value={estimate.comment === null ? "" : estimate.comment}
            multiline
            onChange={(e) =>
              setEstimate({ ...estimate, comment: e.target.value })
            }
            fullWidth
            id="commentestimate"
            maxRows={4}
            label="Comment"
          />
        </div>
      </div>
      <div>
        <Stack style={{ margin: "20px 0 10px 0" }} direction="row" spacing={1}>
          <Button
            disabled={
              estimate.tons === null ||
              estimate.tons === "" ||
              isNaN(estimate.tons)
            }
            variant="contained"
            color="primary"
            onClick={() => save()}
          >
            SAVE
          </Button>
          <Button variant="outline" color="secondary" onClick={onCancel}>
            CANCEL
          </Button>
        </Stack>
      </div>
      <div className="previousCont">
        {block?.blockEstimates?.map((estimate) => (
          <div key={estimate.id}>
            <div className="tons">{estimate.tons} tons</div>
            <div className="comment" style={{ whiteSpace: "pre-wrap" }}>
              {estimate.comment}
            </div>
            <div className="email">{estimate.email}</div>
            <div className="date">{estimate.createdAt}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
