import React, { useEffect, useState } from "react"
import { Button } from "@mui/material"
import Stack from "@mui/material/Stack"

// this component will be used to edit the programs associated with a block
// a program has a program code, a percentage, and isDefault boolean
// this component should be a table and allow for adding and deleting programs
export const ProgramEditor = ({ block, onCancel, onSave, md, readOnly }) => {
  const [programs, setPrograms] = useState(
    block && block.blockPrograms
      ? JSON.parse(JSON.stringify(block?.blockPrograms))
      : []
  )

  useEffect(() => {
    if (readOnly) {
      setPrograms(
        block && block.blockPrograms
          ? JSON.parse(JSON.stringify(block?.blockPrograms))
          : []
      )
    }
  }, [block, readOnly])

  const [error, setError] = useState(null)

  if (!block || !md) {
    return null
  }

  const { blockEstimates } = block
  const latestEstimate =
    blockEstimates && blockEstimates.length > 0 ? blockEstimates[0] : null

  const contractTons = block.externalContract
    ? block.externalContract.contractTons
    : block.contractTons

  const effectiveTons = latestEstimate ? latestEstimate.tons : contractTons

  const save = () => {
    let defaultCount = 0
    let percentTotal = 0
    let valid = true
    const uniqueCombos = new Set()
    programs.forEach((program) => {
      if (program.isDefault) {
        defaultCount++
      }
      percentTotal += program.percent
      if (program.programCode.length < 7) {
        valid = false
      }
      const combo = `${program.programCode}-${program.locationCode}`
      if (uniqueCombos.has(combo)) {
        valid = false
      } else {
        uniqueCombos.add(combo)
      }
    })

    if (defaultCount !== 1 || percentTotal !== 100 || !valid) {
      if (defaultCount !== 1) {
        setError("There must be exactly one default program")
      }
      if (percentTotal !== 100) {
        setError("Percentages must add up to 100")
      }
      if (!valid) {
        setError(
          "Program code must be at least 7 characters and program/location combos must be unique"
        )
      }

      return
    }

    onSave(block.id, programs)
  }

  const allLocations = md.locations

  const calculateEffectiveTons = (program) => {
    let minTotal = 0
    let percentTotal = 0
    programs.forEach((p) => {
      if (p.min) {
        minTotal += p.min
      } else {
        percentTotal += p.percent
      }
    })

    let remainingTons = effectiveTons - minTotal
    let programTons = 0
    if (program.min) {
      if (remainingTons >= 0) {
        if (percentTotal === 0) {
          programTons = (program.min / minTotal) * effectiveTons
        } else {
          programTons = program.min
        }
      } else {
        programTons = (program.min / minTotal) * effectiveTons
      }
    } else {
      if (remainingTons > 0) {
        programTons = (program.percent / percentTotal) * remainingTons
      }
    }

    return programTons
  }

  return (
    <div className="ProgramEditor">
      {!readOnly && (
        <div className="blockInfo" style={{ marginBottom: "10px" }}>
          <div>Block: {block.description}</div>
          <div>Contract Tons: {contractTons || "N/A"}</div>
          <div>
            Latest Estimate: {latestEstimate ? latestEstimate.tons : "N/A"}
          </div>
        </div>
      )}
      {error && <div style={{ color: "red", fontWeight: "bold" }}>{error}</div>}
      <div>
        <table>
          <thead>
            <tr>
              {readOnly ? null : <th></th>}
              <th>Default?</th>
              <th>Location</th>
              <th>Program Code</th>
              <th>Percentage</th>
              <th>Fixed Tons</th>
              <th>Effective Tons</th>
            </tr>
          </thead>
          <tbody>
            {programs.map((program, index) => (
              <tr key={index}>
                {!readOnly && (
                  <td style={{ textAlign: "center" }}>
                    <Button
                      onClick={() => {
                        const newPrograms = [...programs]
                        newPrograms.splice(index, 1)
                        setPrograms(newPrograms)
                        setError(null)
                      }}
                      color="error"
                      size="small"
                    >
                      Remove
                    </Button>
                  </td>
                )}
                <td style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    checked={program.isDefault}
                    disabled={readOnly}
                    onChange={(e) => {
                      const newPrograms = programs.map((program, i) => {
                        if (i === index) {
                          program.isDefault = e.target.checked
                        } else {
                          program.isDefault = false
                        }
                        return program
                      })
                      setPrograms(newPrograms)
                      setError(null)
                    }}
                  />
                </td>
                {readOnly ? (
                  <td>{program.locationCode}</td>
                ) : (
                  <td>
                    <select
                      value={program.locationCode}
                      onChange={(e) => {
                        const newPrograms = [...programs]
                        newPrograms[index].locationCode = e.target.value
                        setPrograms(newPrograms)
                        setError(null)
                      }}
                    >
                      {allLocations.map((location) => (
                        <option key={location.code} value={location.code}>
                          {location.name}
                        </option>
                      ))}
                    </select>
                  </td>
                )}
                <td>
                  <input
                    type="text"
                    disabled={readOnly}
                    value={program.programCode}
                    onChange={(e) => {
                      const newPrograms = [...programs]
                      newPrograms[index].programCode = e.target.value
                      setPrograms(newPrograms)
                      setError(null)
                    }}
                  />
                </td>
                <td>
                  <input
                    disabled={readOnly}
                    type="text"
                    className="minwidth"
                    value={program.percent || 0}
                    onChange={(e) => {
                      const newPrograms = [...programs]
                      newPrograms[index].percent = Number(e.target.value)
                      setPrograms(newPrograms)
                      setError(null)
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    disabled={readOnly}
                    className="minwidth"
                    value={program.min || ""}
                    onChange={(e) => {
                      const newPrograms = [...programs]
                      newPrograms[index].min =
                        e.target.value === "" ? null : Number(e.target.value)
                      setPrograms(newPrograms)
                      setError(null)
                    }}
                  />
                </td>
                <td>
                  {effectiveTons
                    ? calculateEffectiveTons(program).toFixed(2)
                    : "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!readOnly && (
        <>
          <div>
            <Button
              color="success"
              onClick={() =>
                setPrograms([
                  ...programs,
                  {
                    programCode: "",
                    isDefault: false,
                    percent: 1,
                    min: null,
                    locationCode: "RE",
                  },
                ])
              }
            >
              Add Program
            </Button>
          </div>
          <div>
            <Stack
              style={{ margin: "20px 0 10px 0" }}
              direction="row"
              spacing={1}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => save()}
              >
                SAVE
              </Button>
              <Button variant="outline" color="secondary" onClick={onCancel}>
                CANCEL
              </Button>
            </Stack>
          </div>
        </>
      )}
    </div>
  )
}
