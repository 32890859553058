import React from "react"
import Popper from "@mui/material/Popper"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Button from "@mui/material/Button"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import IconButton from "@mui/material/IconButton"

import "../styles/FilterController.css"
import { Autocomplete } from "@mui/material"

export const blockDataToFilterFields = (blockData) => {
  let varietyOptions = {}
  let growerOptions = {}
  let growerCodeOptions = {}
  let repOptions = {}
  let contractOptions = {}
  let contractStatusOptions = {}
  let districtOptions = {}
  let appellationOptions = {}
  let programOptions = {}

  if (blockData && blockData.length > 0) {
    blockData.forEach((b) => {
      const varIDToUse =
        b.externalContract?.variety?.id || (b.variety && b.variety?.id)
      const varDescToUse =
        b.externalContract?.variety?.description ||
        (b.variety && b.variety?.description)
      if (varIDToUse && varDescToUse) {
        varietyOptions[varIDToUse] = {
          key: varIDToUse,
          label: varDescToUse,
        }
      }

      const appIDToUse =
        b.externalContract?.appellation?.id || b.appellationCode
      if (appIDToUse) {
        appellationOptions[appIDToUse] = {
          key: appIDToUse,
          label: appIDToUse,
        }
      }

      const growerValToUse = b.externalContract?.growerName || b.growerName
      if (growerValToUse) {
        growerOptions[growerValToUse] = {
          key: growerValToUse,
          label: growerValToUse,
        }
      }

      if (b.rep && b.rep.id) {
        repOptions[b.rep.id] = {
          key: b.rep.id,
          label: b.rep.name,
        }
      }

      if (b.contractstatus && b.contractstatus.id) {
        contractStatusOptions[b.contractstatus.id] = {
          key: b.contractstatus.id,
          label: b.contractstatus.name,
        }
      }

      if (b.externalContract && b.externalContract.id) {
        contractOptions[b.externalContract.id] = {
          key: b.externalContract.id,
          label: b.externalContract.id,
        }
      }

      if (b.growerID || b.externalContract?.growerId) {
        const district = (b.growerID || b.externalContract?.growerId)?.slice(-2)
        districtOptions[district] = {
          key: district,
          label: district,
        }
      }

      if (b.growerID || b.externalContract?.growerId) {
        const growerID = b.growerID || b.externalContract?.growerId
        growerCodeOptions[growerID] = {
          key: growerID,
          label: growerID,
        }
      }

      const multiPrograms = !!b.blockPrograms
      if (multiPrograms) {
        b.blockPrograms.forEach((bp) => {
          programOptions[bp.programCode] = {
            key: bp.programCode,
            label: bp.programCode,
          }
        })
      } else {
        if (b.programCode) {
          programOptions[b.programCode] = {
            key: b.programCode,
            label: b.programCode,
          }
        }
      }
    })

    varietyOptions = Object.keys(varietyOptions)
      .sort()
      .reduce((acc, key) => {
        acc[key] = varietyOptions[key]
        return acc
      }, {})

    appellationOptions = Object.keys(appellationOptions)
      .sort()
      .reduce((acc, key) => {
        acc[key] = appellationOptions[key]
        return acc
      }, {})
    growerOptions = Object.keys(growerOptions)
      .sort()
      .reduce((acc, key) => {
        acc[key] = growerOptions[key]
        return acc
      }, {})
    growerCodeOptions = Object.keys(growerCodeOptions)
      .sort()
      .reduce((acc, key) => {
        acc[key] = growerCodeOptions[key]
        return acc
      }, {})
    repOptions = Object.keys(repOptions)
      .sort()
      .reduce((acc, key) => {
        acc[key] = repOptions[key]
        return acc
      }, {})
    contractStatusOptions = Object.keys(contractStatusOptions)
      .sort()
      .reduce((acc, key) => {
        acc[key] = contractStatusOptions[key]
        return acc
      }, {})
    contractOptions = Object.keys(contractOptions)
      .sort()
      .reduce((acc, key) => {
        acc[key] = contractOptions[key]
        return acc
      }, {})
    districtOptions = Object.keys(districtOptions)
      .sort()
      .reduce((acc, key) => {
        acc[key] = districtOptions[key]
        return acc
      }, {})

    programOptions = Object.keys(programOptions)
      .sort()
      .reduce((acc, key) => {
        acc[key] = programOptions[key]
        return acc
      }, {})
  } else {
    return null
  }

  const filterFields = [
    {
      fieldKey: "repId",
      label: "Rep",
      options: Object.values(repOptions),
      getValueKey: (x) => x.rep?.id,
    },
    {
      fieldKey: "contractstatusId",
      label: "Contract Status",
      options: Object.values(contractStatusOptions),
      getValueKey: (x) => x.contractstatus?.id,
    },
    {
      fieldKey: "variety",
      label: "Variety",
      options: Object.values(varietyOptions),
      getValueKey: (x) =>
        x.externalContract ? x.externalContract.varietyCode : x.varietyId,
    },
    {
      fieldKey: "appellation",
      label: "Appellation",
      options: Object.values(appellationOptions),
      getValueKey: (x) =>
        x.externalContract
          ? x.externalContract.appellationCode
          : x.appellationId,
    },
    {
      fieldKey: "growerName",
      label: "Grower",
      options: Object.values(growerOptions),
      getValueKey: (x) =>
        x.externalContract && x.externalContract.growerName
          ? x.externalContract.growerName
          : x.growerName,
    },
    {
      fieldKey: "growerID",
      label: "Grower Code",
      options: Object.values(growerCodeOptions),
      getValueKey: (x) =>
        x.externalContract && x.externalContract.growerId
          ? x.externalContract.growerId
          : x.growerID,
    },
    {
      fieldKey: "externalContractId",
      label: "Contract",
      options: Object.values(contractOptions),
      getValueKey: (x) => x.externalContract?.id,
    },
    {
      fieldKey: "district",
      label: "District",
      options: Object.values(districtOptions),
      getValueKey: (x) => {
        return x.growerID
          ? x.growerID.slice(-2)
          : x.externalContract?.growerId?.slice(-2)
      },
    },
    {
      fieldKey: "programCode",
      label: "Program",
      options: Object.values(programOptions),
      multi: true,
      getValueKey: (x) => {
        return x.blockPrograms
          ? x.blockPrograms.map((bp) => bp.programCode)
          : [x.programCode]
      },
    },
  ]

  return filterFields
}

// this component will show a button to open the filter Material UI Popper
// it will rely on props for the options and values of the filter state
// it will also rely on props to update the filter state
export const FilterController = ({ fields, values, onChange, clearAll }) => {
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setOpen((prev) => !prev)
    setAnchorEl(event.currentTarget)
  }

  const numFilters = values
    ? Object.keys(values)
        .map((x) => values[x])
        .filter((x) => x.length > 0).length
    : 0

  return (
    <div className="FilterController">
      <div
        aria-describedby={"sss"}
        className="button"
        type="button"
        onClick={handleClick}
      >
        {numFilters > 0 ? `FILTERS: ${numFilters}` : "FILTER"}
      </div>
      <Popper
        id={"sss"}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <Paper className="filterBox">
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false)
            }}
          >
            <div>
              <div className="filterTopBar">
                <Button
                  className="clearButton"
                  onClick={() => {
                    clearAll()
                    setOpen(false)
                  }}
                >
                  CLEAR ALL
                </Button>
                <IconButton onClick={() => setOpen(false)}>
                  <CloseOutlinedIcon />
                </IconButton>
              </div>
              <div className="filtersContainer">
                {fields?.map((field, index) => (
                  <div className="filterItem" key={index}>
                    <div className="filterItemLabel">{field.label}</div>
                    <Autocomplete
                      size="small"
                      limitTags={2}
                      className="filterItemInput"
                      options={field.options}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.key === value.key
                      }
                      multiple
                      value={
                        values[field.fieldKey]?.length > 0
                          ? values[field.fieldKey]
                          : []
                      }
                      onChange={(x, y, z) => {
                        onChange(field.fieldKey, y)
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>
                ))}
              </div>
            </div>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  )
}
