import React from "react"

export const DateRangePicker = ({ value, setDateRange, disabled }) => {
  return (
    <div className="dateRangeSelector">
      <input
        disabled={disabled}
        type="date"
        value={value.startDate}
        onChange={(e) => setDateRange({ ...value, startDate: e.target.value })}
      />
      <input
        disabled={disabled}
        type="date"
        value={value.endDate}
        onChange={(e) => setDateRange({ ...value, endDate: e.target.value })}
      />
    </div>
  )
}

export default DateRangePicker
